
export type VersandInfoServerResponse = {
    standardSpediteur:string,
    standardVersandart:string,
    versandOptionen:VersandOption[][],
    zugrundeliegendesLand:string,
    anzeigeNamenSpediteur:[]
};

export type VersandOption = {brutto, netto, laufzeit, pauschalen, bruttoNaechsteStufe, bestellwertNaechsteStufe};
export type VersandInformationen = {spediteur:string, versandart:string, versandOption:VersandOption, anzeigeNameSpediteur};

export function getDateFromPHPMonthDay(monthDay:MonthDay) {
    /*
    Gott, das war ein nerviger Bug,
    Situation: 31.5.
    setMonth(5) setzt denn Monat auf Juni.
    Der Hat aber nur 30 Tage, denkt sich Javascript, also ist wohl der erste Juli gemeint. (leider korrektes Verhalten)
    Dann setze ich den Tag auf 21
    raus kommt der 21.7 statt der 21.6.
     */

    const date = new Date();
    date.setDate(1); //s.o.
    date.setMonth(monthDay.month -1); // Javascript Months are 0 based
    date.setDate(monthDay.day);
    return date;
}

export class WarenkorbVersandTermine {
    public standard: VersandTermin | null;
    public express: VersandTermin | null;
    public superexpress: VersandTermin | null;

    public constructor(standard: VersandTermin | null, express: VersandTermin | null, superexpress: VersandTermin | null) {
        this.standard = standard;
        this.express = express;
        this.superexpress = superexpress;
    }

    public getTermin(terminname:WarenkorbProduktionsarten) {
        switch (terminname) {
            case WarenkorbProduktionsarten.EXPRESS:
                return this.express;
            case WarenkorbProduktionsarten.SUPEREXPRESS:
                return this.superexpress;
            case WarenkorbProduktionsarten.STANDARD :
            default:
                return this.standard;
        }
    }
}

export class MonthDay {
    public month;
    public day;
}

export class VersandTermin {
    public bestelltermin;
    public freigabetermin;
    public versandtermin:MonthDay;
    public produktionsart;
}



export class WarenkorbProduktionsarten {
    public static STANDARD = "standard";
    public static EXPRESS = "express";
    public static SUPEREXPRESS = "superexpress";
}
