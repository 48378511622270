import {type EnumDictionary} from "@k/js/delme_globals";
import {CONSTANTS} from "@k/js/meta_data";


type initParameters = {
    sprache: string,
    land: string,
    domain: string
}

export enum LinksToWebsiteShop {
    agbs = "agbs",
    faq = "faq_neu",
    lieferzeit = "lieferzeit_neu",
    privacyterms = "datenschutzerklaerung",
}


import {defineStore} from "pinia";
import type {Ref} from "vue";
import {useShopInfos} from "~/store/shopInfosStore";


export const useFooterStore = defineStore("footerstore", () => {

        const sprache = ref("de");
        const domain = ref("");
        const land = ref("de");
        const locale = ref("");
        const internationalLinks = ref({
            agbs: "",
            lieferzeit_neu: "",
            datenschutzerklaerung: ""
        }) as Ref<EnumDictionary<LinksToWebsiteShop, string>>;
        const owayoImpressum = ref("");

        const actions = {
            getSprache: computed(() => sprache.value),
            getDomain: computed(() => domain.value),
            getLand: computed(() => land.value),
            getLanguage: computed(() => sprache.value),
            getLocale: computed(() => locale.value),
            getLinkToWebsite: computed(() => (link: LinksToWebsiteShop) => internationalLinks.value[link]),
            getLanguageIso: computed((): string => {
                if (land.value === "us" && sprache.value !== "es") {
                    return land.value;
                }
                if (land.value.toLowerCase() === "ca" && sprache.value.toLowerCase() === "fr") {
                    return sprache.value;
                }
                if (CONSTANTS.LAENDER_WHERE_LAND_IST_LANGUAGE_ISO.indexOf(land.value.toLowerCase()) > -1) {
                    return land.value;
                }
                return sprache.value;
            }),
            getOwayoImpressum: computed(() => owayoImpressum.value),
            INIT_FOOTER_STORE(payload: initParameters): void {
                sprache.value = payload.sprache;
                land.value = payload.land;
                domain.value = payload.domain;
            },
            SET_WEBSITE_LINKS(links: EnumDictionary<LinksToWebsiteShop, string>): void {
                internationalLinks.value = links;
            },
            SET_OWAYO_IMPRESSUM_CONTENT(content: string): void {
                owayoImpressum.value = content;
            },
            SET_SHOP_SPRACHE(spracheToSet: string) {
                sprache.value = spracheToSet;
            },
            loadImpressum() {
                const shopInfos = useShopInfos();

                useFetch("/shop_php/load_impressum_directly.php?sprache=" + shopInfos.getShopSprache + "&land=" + shopInfos.getShopLand)
                    .then((response) => {
                            actions.SET_OWAYO_IMPRESSUM_CONTENT(response.data.value as string)
                        }
                    )
            }
        };

        return actions;

    }
)
