import {CONSTANTS} from "../js/meta_data";
import {EventListener, EventVerteiler} from "./eventVerteiler";
import {INTERFRAMEMESSAGES} from "./InterframeMessages";

export class UserManagementConnectorRoutes extends EventListener{
    private static language = "de";
    private static pathOverride = "";
    public static initLanguage(language:string) {
        UserManagementConnectorRoutes.language = language;
    }

    public static setPathOverride(pathOverride:string) {
        UserManagementConnectorRoutes.pathOverride = pathOverride;
    }

    public constructor() {
        super();
        this.addEventListener(INTERFRAMEMESSAGES.USERMANAGEMENT_CLOSE, ()=>this.close())
        this.addEventListener(INTERFRAMEMESSAGES.ROUTE_CHANGE, (data:{component:string, routename:string})=>{
            if(data.component==="usermanagement_routes"){
                document.getElementById("iFrameUserManagement").dataset.target = data.routename
            }

        })
    }


    public static getURL(route) {
        return (UserManagementConnectorRoutes.pathOverride !== "" ? UserManagementConnectorRoutes.pathOverride : CONSTANTS.USERMANAGEMENT_PATH())+UserManagementConnectorRoutes.language+"/"+route;
    }

    private open = (route, targetID = "iFrameUserManagement") => this.openAndFocusIframe(targetID, UserManagementConnectorRoutes.getURL(route), route);
    public login = (targetid?) => this.open("login", targetid);
    public logout = (targetid?) => this.open("logout", targetid);
    public openMyAccount = (targetid?) => this.open("myaccount", targetid);
    public changeEmail = (targetid?) => this.open("change_email", targetid)
    public changePassword = (targetid?) => this.open("change_password", targetid)
    public openAdressen = (targetid?) => this.open("adresses", targetid);
    public openChooseAdresse = (targetID, wkid, adressType="") => this.open(wkid+"/adresses/choose"+(adressType !== '' ? ("/"+adressType) : ""), targetID);
    public openNewDeliveryAdress = (targetID?) => this.open("adresses/new/delivery", targetID)
    public openAdressesWithChooseAsOption = (targetid?: string) => this.open("adresses/manageWithUseAsOption", targetid);

    protected openAndFocusIframe(targetID, url, routename) {
        const iFrame = document.getElementById(targetID) as HTMLIFrameElement;
        delete iFrame.style.minHeight;
        iFrame.removeAttribute("width");
        iFrame.removeAttribute("style");
        EventVerteiler.broadcastBlockRequest("usermanagement");
        iFrame.classList.remove("ele-hidden");
        iFrame.dataset.target= routename;
        iFrame.src=url;
        (iFrame.contentWindow as Window).focus();
    }

    public close = (targetid = "iFrameUserManagement") => {
        const iframe = document.getElementById(targetid) as HTMLIFrameElement;
        iframe.classList.add("ele-hidden")
        iframe.src="";
        EventVerteiler.broadcastBlockRequestEnded("usermanagement")
    };
}
