<template>
  <div class="popup_twobutton modal-wrapper">
    <div class="popup_twobutton km__popup small">
      <h2 class="km__popup--heading">{{ dialogState.header }}</h2>
      <div class="km__popup--content">
        <p v-html="dialogState.message"></p>
      </div>
      <div class="km__popup--buttons" :class="displayCancel ? 'twobuttons' :'onebutton'">
        <button v-if="displayCancel" class="km__button--standard cancel" @click="dialogState.callbackOnCancel">
          {{ dialogState.shouldTranslateButtons ? $t(canceltextID) : canceltextID }}
        </button>
        <button class="km__button--standard blue" @click="dialogState.callbackOnConfirm">
          {{ dialogState.shouldTranslateButtons ? $t(confirmTextID) : confirmTextID }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import {canceltextID, ConfirmOrConfirmCancelDialog} from "@km/components/popups/ConfirmOrConfirmCancelDialog";
import {confirmTextID} from "@km/components/popups/ConfirmOrConfirmCancelDialog";

const dialogState = ConfirmOrConfirmCancelDialog.dialogState;
const displayCancel = computed(() => {
      return ConfirmOrConfirmCancelDialog.dialogState.value.displayType === "ConfirmCancel"
    }
);
</script>

<style scoped lang="scss">
.modal-wrapper {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0;

  .km__popup--heading {
    margin-bottom: 20px;
  }
}

.popup_twobutton.km__popup {
  height: 350px;

  .km__button--standard {
    padding: 0 10px;
  }

  .km__popup--content {
    p {
      font-size: 13px;
      line-height: 1.45em;
      margin-bottom: 15px;
    }
  }
}
</style>
