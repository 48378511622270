
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "es",
  "fr",
  "it",
  "jp",
  "kr",
  "nl",
  "us"
]

export const localeLoaders = {
  de: [
    {
      key: "locale_de_46ts_fa65fa2d",
      load: () => import("#nuxt-i18n/fa65fa2d" /* webpackChunkName: "locale_de_46ts_fa65fa2d" */),
      cache: false
    }
  ],
  en: [
    {
      key: "locale_en_46ts_f97850f7",
      load: () => import("#nuxt-i18n/f97850f7" /* webpackChunkName: "locale_en_46ts_f97850f7" */),
      cache: false
    }
  ],
  es: [
    {
      key: "locale_es_46ts_79bd8f58",
      load: () => import("#nuxt-i18n/79bd8f58" /* webpackChunkName: "locale_es_46ts_79bd8f58" */),
      cache: false
    }
  ],
  fr: [
    {
      key: "locale_fr_46ts_fbc592d9",
      load: () => import("#nuxt-i18n/fbc592d9" /* webpackChunkName: "locale_fr_46ts_fbc592d9" */),
      cache: false
    }
  ],
  it: [
    {
      key: "locale_it_46ts_9b8335ef",
      load: () => import("#nuxt-i18n/9b8335ef" /* webpackChunkName: "locale_it_46ts_9b8335ef" */),
      cache: false
    }
  ],
  jp: [
    {
      key: "locale_jp_46ts_d71db94b",
      load: () => import("#nuxt-i18n/d71db94b" /* webpackChunkName: "locale_jp_46ts_d71db94b" */),
      cache: false
    }
  ],
  kr: [
    {
      key: "locale_kr_46ts_51775b3c",
      load: () => import("#nuxt-i18n/51775b3c" /* webpackChunkName: "locale_kr_46ts_51775b3c" */),
      cache: false
    }
  ],
  nl: [
    {
      key: "locale_nl_46ts_7e8f293b",
      load: () => import("#nuxt-i18n/7e8f293b" /* webpackChunkName: "locale_nl_46ts_7e8f293b" */),
      cache: false
    }
  ],
  us: [
    {
      key: "locale_us_46ts_68461cdd",
      load: () => import("#nuxt-i18n/68461cdd" /* webpackChunkName: "locale_us_46ts_68461cdd" */),
      cache: false
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "de",
      files: [
        "E:/www/shop_nuxt/i18n/lang/de.ts"
      ]
    },
    {
      code: "en",
      files: [
        "E:/www/shop_nuxt/i18n/lang/en.ts"
      ]
    },
    {
      code: "es",
      files: [
        "E:/www/shop_nuxt/i18n/lang/es.ts"
      ]
    },
    {
      code: "fr",
      files: [
        "E:/www/shop_nuxt/i18n/lang/fr.ts"
      ]
    },
    {
      code: "it",
      files: [
        "E:/www/shop_nuxt/i18n/lang/it.ts"
      ]
    },
    {
      code: "jp",
      files: [
        "E:/www/shop_nuxt/i18n/lang/jp.ts"
      ]
    },
    {
      code: "kr",
      files: [
        "E:/www/shop_nuxt/i18n/lang/kr.ts"
      ]
    },
    {
      code: "nl",
      files: [
        "E:/www/shop_nuxt/i18n/lang/nl.ts"
      ]
    },
    {
      code: "us",
      files: [
        "E:/www/shop_nuxt/i18n/lang/us.ts"
      ]
    }
  ],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "lang",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "de",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/de.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/en.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/es.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/fr.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/it.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "jp",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/jp.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "kr",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/kr.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "nl",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/nl.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "us",
    files: [
      {
        path: "E:/www/shop_nuxt/i18n/lang/us.ts",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/
if(import.meta.hot) {

function deepEqual(a, b, ignoreKeys = []) {
  // Same reference?
  if (a === b) return true

  // Check if either is null or not an object
  if (a == null || b == null || typeof a !== 'object' || typeof b !== 'object') {
    return false
  }

  // Get top-level keys, excluding ignoreKeys
  const keysA = Object.keys(a).filter(k => !ignoreKeys.includes(k))
  const keysB = Object.keys(b).filter(k => !ignoreKeys.includes(k))

  // Must have the same number of keys (after ignoring)
  if (keysA.length !== keysB.length) {
    return false
  }

  // Check each property
  for (const key of keysA) {
    if (!keysB.includes(key)) {
      return false
    }

    const valA = a[key]
    const valB = b[key]

    // Compare functions stringified
    if (typeof valA === 'function' && typeof valB === 'function') {
      if (valA.toString() !== valB.toString()) {
        return false
      }
    }
    // If nested, do a normal recursive check (no ignoring at deeper levels)
    else if (typeof valA === 'object' && typeof valB === 'object') {
      if (!deepEqual(valA, valB)) {
        return false
      }
    }
    // Compare primitive values
    else if (valA !== valB) {
      return false
    }
  }

  return true
}



async function loadCfg(config) {
  const nuxt = useNuxtApp()
  const { default: resolver } = await config()
  return typeof resolver === 'function' ? await nuxt.runWithContext(() => resolver()) : resolver
}


  import.meta.hot.accept("../i18n/lang/de.ts", async mod => {
    localeLoaders["de"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("de")
  })

  import.meta.hot.accept("../i18n/lang/en.ts", async mod => {
    localeLoaders["en"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("en")
  })

  import.meta.hot.accept("../i18n/lang/es.ts", async mod => {
    localeLoaders["es"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("es")
  })

  import.meta.hot.accept("../i18n/lang/fr.ts", async mod => {
    localeLoaders["fr"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("fr")
  })

  import.meta.hot.accept("../i18n/lang/it.ts", async mod => {
    localeLoaders["it"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("it")
  })

  import.meta.hot.accept("../i18n/lang/jp.ts", async mod => {
    localeLoaders["jp"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("jp")
  })

  import.meta.hot.accept("../i18n/lang/kr.ts", async mod => {
    localeLoaders["kr"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("kr")
  })

  import.meta.hot.accept("../i18n/lang/nl.ts", async mod => {
    localeLoaders["nl"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("nl")
  })

  import.meta.hot.accept("../i18n/lang/us.ts", async mod => {
    localeLoaders["us"][0].load = () => Promise.resolve(mod.default)
    await useNuxtApp()._nuxtI18nDev.resetI18nProperties("us")
  })



}
/** client-end **/