import {ErrorHandler} from "./ErrorHandler";
import {LokalisierungsProvider} from "./Localizer";
import {Resource} from "./resourcemanager/resource";
import {IResourceManager} from "./resourcemanager/IResourceManager";
import type {StringDictionary} from "@k/js/delme_globals";

export class LanguageLoader {
    private static resourceManager: IResourceManager;
    private static errorHandler: ErrorHandler;
    private static localizer:LokalisierungsProvider

    public static setErrorHandler(errorHandler:ErrorHandler) {
        LanguageLoader.errorHandler = errorHandler;
    }

    public static setLocalizer(localizer:LokalisierungsProvider) {
        LanguageLoader.localizer = localizer;
    }

    public static setResourceManager(resourceManager:IResourceManager) {
        LanguageLoader.resourceManager = resourceManager;
    }

    public static loadLanguageTexts(languageIso:string, language:string, kundenKonfi:string) {
        return new Promise(resolve=>{
            LanguageLoader.registerLanguageFileResource(languageIso);
            LanguageLoader.registerOverridesResource(kundenKonfi);

            LanguageLoader.resourceManager.createTask("ControllerTexte")
                .discardAfterExecution()
                .when("languageTexts", "loaded")
                .andWhen("dataModel", "sprache toBeNotEmpty")
                .andWhen("contentOverrides", "loaded")
                .thenDo(function(resourcedata){
                    const mergedTexts = LanguageLoader.mergeLanguageFiles(language, languageIso, resourcedata.languageTexts, resourcedata.contentOverrides);
                    LanguageLoader.initializeLanguageTexts(mergedTexts);
                    resolve(mergedTexts);
                })
        })

    }

    private static registerLanguageFileResource(languageIso:string) {
        LanguageLoader.resourceManager.registerResource({
                resourceName: "languageTexts",
                failHandler: function () {
                    LanguageLoader.errorHandler.handleError("ajaxFail", "translation",
                        "could not load the translation file, sorry");
                }

            }
        ).load({url: "/konfigurator_html/languageFiles/texts-" + languageIso.toLowerCase() + "-lowercaseid.json"});
    }

    private static registerOverridesResource(kundenKonfi:string) {
        if(kundenKonfi !== "") {
            LanguageLoader.resourceManager.registerResource({
                resourceName:"contentOverrides",
                failHandler:function(this:Resource) {
                    this.data= [];
                    this.addState("loaded");
                }

            }).load({url:"/konfigurator_html/languageFiles/overrides/"+kundenKonfi+"_contentoverride-lowercaseid.json"});
        } else {
            LanguageLoader.resourceManager.registerResource({resourceName:"contentOverrides", startingStati:["loaded"], data:[]});
        }
    }

    public static mergeLanguageFiles(language:string, land:string, languageTexts:StringDictionary<string>, overrides:StringDictionary<string[]>) {
        if(typeof(overrides[language]) === "undefined" || overrides[language].length === 0) {
            return languageTexts;
        }
        const ids = typeof(overrides[language][land]) === "undefined" ? overrides[language]["*"] : overrides[language][land];
        for(const id in ids) {
            languageTexts[id] = ids[id];
        }
        return languageTexts;
    }

    public static initializeLanguageTexts(languageTexts:StringDictionary<string>) {
        LanguageLoader.localizer.setTexte(languageTexts);
        $("input[placeholder], textarea[placeholder]").each(function () {
            const content = $(this).attr("placeholder") as string;
            const newContent = LanguageLoader.localizer.keyExists(content) ? LanguageLoader.localizer.translate(content) : content;
            $(this).attr("placeholder", newContent);
        });
        $("input[data-translatevalue]").each(function() {
            $(this).val(LanguageLoader.localizer.translate($(this).data("translatevalue")))
        });
        $("img[title], input[title]").each(function () {
            const content = $(this).attr("title") as string;
            $(this).attr("title", LanguageLoader.localizer.translate(content, content));
        });
        LanguageLoader.resourceManager.registerLoadedResourceWithoutData("languageData");
    }
}
