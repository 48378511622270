<template>
  <PopupEditProfil v-if="editProfilOpen" @close_popup="close_editProfile" />
  <PopupEditPwLock v-if="editPwLockOpen" @close_popup="close_editPwLock" />
  <PopupEditLogo v-if="editLogoOpen" @close_popup="close_editLogo" />
  <PopupEditTheme v-if="editThemeOpen" @close_popup="close_editTheme" />
  <PopupEditImpressum v-if="editImpressumOpen" @close_popup="close_editImpressum" @open_component_request="openComponentRequest"/>
  <PopupEditFilter v-if="editFilterOpen" :product="getProductForFilter" @close_popup="close_editFilter" />
  <PopupEditColors v-if="editColorsOpen" :product="getProductForFilter" @close_popup="close_editColors" />
</template>

<script lang="ts">
import {computed} from "vue";
import {PopupNames, PopupStatus} from "~/components/PopupHandler";
import PopupEditProfil from "~/components/PopupEditProfil.vue";
import PopupEditLogo from "~/components/PopupEditLogo.vue";
import PopupEditTheme from "~/components/PopupEditTheme.vue";
import PopupEditImpressum from "~/components/PopupEditImpressum.vue";
import PopupEditFilter from "~/components/PopupEditFilter.vue";
import PopupEditColors from "~/components/PopupEditColors.vue";
import PopupEditPwLock from "~/components/PopupEditPwLock.vue";
import {Navigation_opencomponentHandler_SHOP} from "~/navigation_opencomponentHandler_SHOP";
//@ts-ignore
import {NavigationOpenRequestTypeFromShoppingcartMenu} from "owayo_menu";



export default {
  name: "PopupContainer",
  components: {
    PopupEditImpressum,
    PopupEditTheme,
    PopupEditLogo,
    PopupEditProfil,
    PopupEditFilter,
    PopupEditColors,
    PopupEditPwLock
  },
  setup() {
    return {
      editProfilOpen: computed(() => PopupStatus.editProfilISOpen()),
      editPwLockOpen: computed(() => PopupStatus.editPwLockIsOpen()),
      editLogoOpen: computed(() => PopupStatus.editLogoIsOpen()),
      editThemeOpen: computed(() => PopupStatus.editThemeIsOpen()),
      editImpressumOpen: computed(() => PopupStatus.editImpressumIsOpen()),
      editFilterOpen: computed(() => PopupStatus.editFilterIsOpen()),
      editColorsOpen: computed(() => PopupStatus.editColorsIsOpen()),
      getProductForFilter: computed(() => PopupStatus.getProductForFilter()),
      close_editProfile: () => PopupStatus.TOGGLE_POPUP(PopupNames.editProfil),
      close_editPwLock: () => PopupStatus.TOGGLE_POPUP(PopupNames.editPwLock),
      close_editLogo: () => PopupStatus.TOGGLE_POPUP(PopupNames.editLogo),
      close_editTheme: () => PopupStatus.TOGGLE_POPUP(PopupNames.editTheme),
      close_editImpressum: () => PopupStatus.TOGGLE_POPUP(PopupNames.editImpressum),
      close_editFilter: () => PopupStatus.TOGGLE_POPUP(PopupNames.editFilter),
      close_editColors: () => PopupStatus.TOGGLE_POPUP(PopupNames.editColors),
      openComponentRequest:(component:NavigationOpenRequestTypeFromShoppingcartMenu)=>Navigation_opencomponentHandler_SHOP.open_component(component),
    }

  }
}
</script>

<style scoped lang="scss">

</style>
