import {LanguageLoader} from "@k/global/languageLoader";
import {EnumDictionary, type StringDictionary} from "@k/js/delme_globals";

export class LanguageLoaderVue {
    private static basicTexts = null as null | EnumDictionary<string, string>
    private static overrides = null as null|StringDictionary<string[]>;
    private static mergedTexts = null as null | EnumDictionary<string, string>

    public static loadLanguageTexts(languageIso: string, language: string, land, kundenkonfi: string) {
        return new Promise<EnumDictionary<string, string>>(resolve => {
            fetch("/konfigurator_html/languageFiles/texts-" + languageIso + "-lowercaseid.json")
                .then(loaded => loaded.json())
                .then(data => {
                        LanguageLoaderVue.loadedHandler("basic", data, language, land)
                        if (LanguageLoaderVue.mergedTexts !== null) {
                            resolve(LanguageLoaderVue.mergedTexts)
                        }
                    }
                );
            if (kundenkonfi) {
                fetch("/konfigurator_html/languageFiles/overrides/" + kundenkonfi + "_contentoverride-lowercaseid.json")
                    .then(loaded => loaded.json())
                    .then(data => {
                        LanguageLoaderVue.loadedHandler("overrides", data, language, land);
                        if (LanguageLoaderVue.mergedTexts !== null) {
                            resolve(LanguageLoaderVue.mergedTexts);
                        }
                    })
            } else {
                LanguageLoaderVue.overrides = [] as unknown as StringDictionary<string[]>;
            }

        })
    }

    private static loadedHandler(basicOrOverride: "basic" | "overrides", texts, language, land) {
        if (basicOrOverride === "basic") {
            LanguageLoaderVue.basicTexts = texts;
        } else {
            LanguageLoaderVue.overrides = texts;
        }
        if (LanguageLoaderVue.basicTexts === null || LanguageLoaderVue.overrides === null) {
            return;
        }
        LanguageLoaderVue.mergedTexts = LanguageLoader.mergeLanguageFiles(language, land, LanguageLoaderVue.basicTexts, LanguageLoaderVue.overrides);
    }


    public static getLanguageFromDomain(domain: string) {
        const map = new Map();
        map.set("www.owayo.at", "de");
        map.set("www.owayo.de", "de");
        map.set("www.owayo.ch", "de");
        map.set("www.owayo.fr", "fr");
        map.set("fr.owayo.ch", "fr");
        map.set("fr.owayo.be", "fr");
        map.set("fr.owayo.ca", "fr");
        map.set("fr.owayo.com", "fr");
        map.set("www.owayo.nl", "nl");
        map.set("www.owayo.be", "nl");
        map.set("www.owayo.com", "en");
        map.set("www.owayo.co.nz", "en");
        map.set("www.owayo.com.au", "en");
        map.set("www.owayo.co.uk", "en");
        map.set("www.owayo.ie", "en");
        map.set("en.owayo.com", "en");
        map.set("www.owayo.ca", "en");
        map.set("www.owayo.it", "it");
        map.set("it.owayo.ch", "it");
        map.set("www.owayo.es", "es");
        map.set("es.owayo.com", "es");
        map.set("us-es.owayo.com", "es");
        map.set("www.owayo.jp", "jp");
        map.set("www.owayo.kr", "kr");
        map.set("www.owayo.ru", "en");
        map.set("www.owayo.pl", "en");
        map.set("www.owayo.dk", "en");
        map.set("www.owayo.no", "no");

        return map.get(domain) || "de";
    }

    public static getLandFromDomain(domain: string) {
        const map = new Map();
        map.set("www.owayo.at", "at");
        map.set("www.owayo.de", "de");
        map.set("www.owayo.ch", "ch");
        map.set("www.owayo.fr", "fr");
        map.set("fr.owayo.ch", "ch");
        map.set("fr.owayo.be", "be");
        map.set("fr.owayo.ca", "ca");
        map.set("fr.owayo.com", "fr");
        map.set("www.owayo.nl", "nl");
        map.set("www.owayo.be", "be");
        map.set("www.owayo.com", "us");
        map.set("www.owayo.co.nz", "nz");
        map.set("www.owayo.com.au", "au");
        map.set("www.owayo.co.uk", "gb");
        map.set("www.owayo.ie", "ie");
        map.set("en.owayo.com", "intl");
        map.set("www.owayo.ca", "ca");
        map.set("www.owayo.it", "it");
        map.set("it.owayo.ch", "ch");
        map.set("www.owayo.es", "es");
        map.set("es.owayo.com", "us");
        map.set("us-es.owayo.com", "us");
        map.set("www.owayo.jp", "jp");
        map.set("www.owayo.kr", "kr");
        map.set("www.owayo.ru", "ru");
        map.set("www.owayo.pl", "pl");
        map.set("www.owayo.dk", "dk");
        map.set("www.owayo.no", "no");

        return map.get(domain) || "de";
    }
}
