export class ShopAdresse {
    public firma : string;
    public ansprechpartner : string;
    public strasse : string;
    public plz : string;
    public ort : string;
    public iso3166 : string;
    public isEU : boolean;

    constructor() {
        this.firma="";
        this.ansprechpartner="";
        this.strasse="";
        this.plz="";
        this.ort="";
        this.iso3166="";
        this.isEU=true;
    }
}

export class ShopZahlungsverbindung{
    public kontoinhaber : string;
    public email : string;
    public iban : string;
    public nameDerBank : string;
    public bic : string;

    constructor() {
        this.kontoinhaber="";
        this.email="";
        this.iban="";
        this.nameDerBank="";
        this.bic="";
    }
}

export class UserShop {
    public shopName: string;
    public shoplogopath : string;
    public error : string;
    public theme : string;
    public themeBgImage : string;
    public email : string;
    public telefon : string;
    // public adresse : ShopAdresse;
    public possibleThemes : [];
    public isFirmaOrJristischePerson : boolean;
    public steuerstatus : string;
    public ustid : string;
    public zahlungsverbindung : string;
    public emailToVerify : string;
    public emailToVerifyCheck : string;
    public ibanValid : boolean;
    public bicValid : boolean;
    public status:string;
    constructor() {
        this.shopName="";
        this.shoplogopath="";
        this.error="";
        this.theme="";
        this.email="";
        this.telefon="";
        // this.adresse=new ShopAdresse();
        this.possibleThemes = [];
        this.isFirmaOrJristischePerson = false;
        this.steuerstatus = "";
        this.ustid = "";
        this.zahlungsverbindung = "";
        this.emailToVerify = "";
        this.emailToVerifyCheck = "";
        this.ibanValid = true;
        this.bicValid = true;
    }
}
