
export const CONSTANTS = {
    USERMANAGEMENT_PATH: ()=> "/usermanagement_routes/",
    DEVICE_PIXEL_RATIO: 1.2,
    RENDER_PARAMS: {
        alpha: true,
        stencil: false,
        antialias: true,
        preserveDrawingBuffer: true
    },
    LOWERLIMIT_COLORCODENR_OVER_SELOBS: "190.0",
    I_AM_A_CLONE: true,
    PERFORMANCE_MAX_TEXTURESIZE: 2048,
    FONT_SIZESTEP: 0.05,
    LOGO_SIZESTEP: 0.05,
    MIN_SCALING: 0.01,

    FLOAT_TOP: 1,
    FLOAT_FREE: 0,
    FLOAT_BOTTOM: -1,
    FLOAT_TOP_AS_STRING: "TOP",
    FLOAT_FREE_AS_STRING: "FREE",
    FLOAT_BOTTOM_AS_STRING: "BOTTOM",

    MAX_BASE_COLORS: 5,

    COLORPICKER_PER_PAGE: 9,
    COLORPICKER_PER_PAGE_SUB900: 5,
    COLORPICKER_PER_PAGE_BETWEEN900AND1200: 7,
    MAINCOLORCODES: ["A", "A_2",  "B", "B_2", "C", "C_2", "D", "D_2", "E", "E_2"],

    COLORVALUE_INT_WHITE: 16777215,
    COLORVALUE_INT_BLACK: 0,

    MODEL_PATH: "/modelle/",
    MODEL_PATH_CDN: "https://static.owayo-cdn.com/modelle/",
    PART_TEXTURE_PATH: '/3D/PartTextures/',
    HILFSMATERIAL__PATH: '/3D/Hilfsmaterialtexturen',
    PART_TEXTURE_PATH_CDN: 'https://static.owayo-cdn.com/3D/PartTextures/',
    PDFCREATOR_LINK: "/konfigurator_php/pdfcreation/createPDF.php",
    HITMAP_SIZE: {width: 256, height: 256},
    HITMAP_BYTES_PER_PIXEL: 4,
    ONE_DEGREE: Math.PI / 180,
    SIX_DEGREE: Math.PI / 30,
    MAX_ZOOM: 6.0,
    MIN_ZOOM: 1,
    CAMERA_STANDARD_FOV: 30,
    TEXTOBJECT_FONTBASESIZE: 120,
    TEXTOBJECT_DEFAULTFONT: "101 Helvetica Neue Medium Conde",
    TEXTOBJECT_OUTLINE_SHAPE: "round", //"bevel" || "round" || "miter";
    INPUT_PROCESSING_DELAY: 500, //ms
    INPUT_PROCESSING_DELAY_LONG: 2000,  //ms
    DEFAULT_TEXT_COLOR: 0,
    HANDLER_WIDTH: 64,
    HANDLER_HEIGHT: 64,

    ANGLE_SNAP: 1,
    ANGLE_ANCHOR_SNAP: 10,
    ANGLE_SNAP_ANCHORS: [-180, -90, 0, 90, 180],
    SNAP_EDGE_PRIO: {left: 1, right: 1, top: 1, bottom: 1, middle: 3, part: 3},
    BASE_COORD_SNAP: 35,
    SNAP_ZOOM_DECREASE: 6,
    HELPER_FADEOUT_STEPS: 30,
    HELPER_FADEOUT_START: 2000, //ms
    HELPER_LINEWIDTH: 3,
    HELPER_COLOR: "lightgrey",

    SNAPSHOT_HEIGHT: 1024,
    SNAPSHOT_RENDER_LEVEL_1024_1024: 1,


    HINT_FADEOUT_START: 5000, //ms
    BUTTON_REPEAT_INTERVALL: 500, //ms
    STANDARD_PALETTE: "CM5 owayo Farbsystem",
    STANDARD_PALETTENCLUSTER: "CM5 Bedruckbar",
    LEGACY_COLORS_PALETTE: "Legacy Colors",
    ADDITIONAL_COLORS_PALETTE: "AddedByOM Colors",

    CAMERA_MOVE_FPS: 30,

    UNDO_DELAY: 50,

    FPS_LIMIT: 40,
    MOUSE_PS_LIMIT: 80,

    COLORERROR: "FF00FF",

    LOGO_SCHWARZ_AUFHELLEN_TARGET_DECIMALVALUE: 0.16,

    ONLY_USE_ENTWURF_FRONT_PREVIEW: true,
    MIN_PIXEL_PER_INCH: 100,
    DELTA_E_THRESHHOLD: 20, //Bis zu diesem Schwellenwert halten wir bei MultiMaster Colors die Farben für ähnlich genug
    LUMINANCE_THRESHHOLD: 100,
    NEUTRAL_COLOR_DELTA: 10,

    MAX_DRAFTPREVIEW_PICTURES: 4,

    CORNER_INDEX: {
        LEFT_BOTTOM: 0,
        RIGHT_BOTTOM: 1,
        RIGHT_TOP: 2,
        LEFT_TOP: 3
    },


    HINTBOX_TOP_POSITION: 50,
    HINTBOX_BOTTOM_POSITION: 20,

    ROTATEMODEL_ON_BUTTONPRESS: Math.PI / 12,
    KONAMICODE: '38384040373937396665',
    KEYCODE: {
        ESCAPE: 27,
        ENTER: 13,
        RETURN: 13,
        DELETE: 46,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
        A: 65,
        B: 66,
        D: 68,
        Y: 89,
        Z: 90,
        NUMPAD0: 96,
        NUMPAD1: 97,
        NUMPAD2: 98,
        NUMPAD3: 99,
        NUMPAD4: 100,
        NUMPAD6: 102,
        NUMPAD7: 103,
        NUMPAD8: 104,
        NUMPAD9: 105

    },

    DATEFORMAT_BYLANGUAGE: {
        en: "DD. MMM YYYY",
        fr: "DD/MM/YYYY",
        it: "DD/MM/YYYY",
        default: "DD.MM.YYYY"
    },

    COLOR_NONE_CLASS: " color-none",
    SIZE_NOT_CHOSEN: "Choose your size",


    NO_RELOAD_NECESSARY: [
        "rotation",
        "relSize",
        "translation",
        "isPinned",
        "isPinned",
        "isFixed",
        "repeat",
        "clampToColor",
        "wasserzeichen",
        "wasserzeichenInvertImage",
        "wasserzeichenIntensity",
        "allowDrag",
        "isEditable",
        "floating",
        "isVorbelegung",
        "allowDrag",
        "allowDelete",
        "allowResize",
        "floating",
        "keepSeparate"
    ],

    UPLOAD_ALLOWED_FILETYPES: "png,gif,jpg,jpeg,pdf,bmp,ai,psd,eps,cdr",
    UPLOAD_ALLOWED_FILETYPES_AS_ARRAY: ["png","gif","jpg","jpeg","pdf","bmp","ai","psd","eps","cdr", "svg"],
    UPLOAD_MAXFILESIZE: 10 * 1024 * 1024,
    UPLOAD_ACCEPTFILES: "image/*, .pdf, .psd, .ai, .cdr, .eps, .svg", //für den Datei Auswahl Dialog. Zeigt jetzt nur Bilddateien an
    UPLOAD_ACCEPTFILES_AS_ARRAY: ["image/*",".pdf", ".psd", ".ai", ".cdr", ".eps"], //für den Datei Auswahl Dialog. Zeigt jetzt nur Bilddateien an
    UPLOAD_CONVERTABLEFORMATS: ["ai", "psd", "cdr", "eps", "pdf"],
	VECTOR_GRAPHICS_EXTENSIONS : [ "svg", "wmf", "eps", "pdf", "cdr", "ai" ],
    LOGOSLIDER_COLUMSPERROW: 4,
    LOGOSLIDER_ROWSPERPAGE: 3,

    EXEMPLARLIST_MAX_ANZ_EXEMPLARE : 250,

    WARNING_SPERRBEZIRK: "WARNING_SPERRBEZIRK",
    WARNING_ILLEGAL_COLLISION: "WARNING_ILLEGALCOLLISION",

    BYTES_PER_PIXEL: 4,
    TRANSPARENCYBIT_OFFSET: 3,
    LAENDER_WHERE_LAND_IST_LANGUAGE_ISO: ["au", "nz", "ca", "ie"],

    ENTWURF_DELETE_ANIMATION_TIMEOUT: 1000,
    KONFI_FUNKTIONALITAETEN: {
        Features: "Features",
        Design: "Design",
        Farben: "Farben",
        Logos: "Logos",
        Texte: "Texte",
    },
    STOCKLOGOCOLORCONTAINERSELECTOR: ".overlay-logo_edit .logo-owayo",
    PATTERNCOLORCONTAINERSELECTOR: ".overlay-pattern_edit_color .pattern-color",
    CHROMOLOGOCANVASWIDTH: 1024,
    RICHTUNGSTASTEN: [] as number[],
    DOMAIN_LANDESSPRACHE_MAP:{}
};

CONSTANTS.RICHTUNGSTASTEN = [
    CONSTANTS.KEYCODE.LEFT, CONSTANTS.KEYCODE.RIGHT, CONSTANTS.KEYCODE.UP, CONSTANTS.KEYCODE.DOWN
];

CONSTANTS.DOMAIN_LANDESSPRACHE_MAP = {
    "www.owayo.de": "de",
    "www.owayo.fr": "fr",
    "www.owayo.at": "at",
    "www.owayo.ch": "ch",
    "fr.owayo.ch": "chfr",
    "fr.owayo.be": "befr",
    "fr.owayo.ca": "cafr",
    "www.owayo.nl": "nl",
    "www.owayo.be": "be",
    "www.owayo.co.uk": "en",
    "www.owayo.com": "us",
    "www.owayo.ca": "ca",
    "www.owayo.ie": "ie",
    "www.owayo.com.au": "au",
    "www.owayo.co.nz": "nz",
    "www.owayo.es": "es",
    "us-es.owayo.com": "uses",
    "fr.owayo.com": "wofr",
    "es.owayo.com": "woes",
    "www.owayo.it": "it"
};


export function stringbeginsWith(string, suffix) {
        return string.indexOf(suffix) === 0;
}

export interface AssociativeArray<T> {
    [key:string]:T
}
