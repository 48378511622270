import {LanguageLoaderVue} from "@km/js/LanguageLoaderVue";
import {useShopInfos} from "~/store/shopInfosStore";
import {useShopItems} from "~/store/shopItemsStore";
import {useShopSeiten} from "~/store/shopSeitenStore";
import {useFooterStore} from "~/store/FooterStore";


export const loadInitialItemsAndPages = async (shopname: string, language: string, land: string) => {
    // Stores initialisieren
    const shopItemStore = useShopItems();
    const shopSeitenStore = useShopSeiten();
    const shopInfoStore = useShopInfos();
    const footerstore = useFooterStore();

    const shopitemsPromise = useFetch(`/shop_php/getAllShopItems.php?shopname=${shopname}&land=${land}&sprache=${language}`);
    const shopseitenPromise = useFetch(`/shop_php/getShopSeiten.php?shopname=${shopname}`);
    const sprachenPromise = useFetch(`/shop_php/getSprachen.php`);
    const owayoURLPromise = useFetch(getURLPrefixOfflineVsOnline_nuxt()+"/newhp/php/getLinkShopToOwayo.php?land="+land+"&language="+language+"&shop="+shopname);
    footerstore.loadImpressum();

    const [shopitemsResponse, shopseitenResponse, sprachenResponse, owayoURL] = await Promise.all([
        shopitemsPromise,
        shopseitenPromise,
        sprachenPromise,
        owayoURLPromise
    ]);

    // Verarbeite die Ergebnisse
    const parsedShopItemResponse = JSON.parse(shopitemsResponse.data.value);
    shopItemStore.setAllShopItems(parsedShopItemResponse.itemsInShop);
    shopItemStore.setFormerShopItems(parsedShopItemResponse.formerShopItems);

    shopSeitenStore.setShopSeiten(JSON.parse(shopseitenResponse.data.value));

    shopInfoStore.setPossibleSprachen(sprachenResponse.data.value);
    shopInfoStore.SET_OWAYO_URL(owayoURL as {data:{value:string}});

    footerstore.SET_SHOP_SPRACHE(language);

}


export const loadInitialShopInfo = async () =>{
    const route = useRoute();

    const url = useRequestURL();

    const land = LanguageLoaderVue.getLandFromDomain(url.hostname);
    const shopInfoStore = useShopInfos();
    shopInfoStore.SET_SHOP_LAND(land);
    shopInfoStore.SET_SHOPDIRECTORY_LOCALIZED(route.params.shopdirectoryLocalized as string);
    shopInfoStore.SET_PRELIMINARY_SHOPNAME(route.params.shopname as string);
    const shopinfoResponse = await useFetch(`/shop_php/getShopInfo.php?shopname=${route.params.shopname}`, {credentials: 'include'});
    shopInfoStore.setShopInfo(shopinfoResponse.data.value);
}

export function getURLPrefixOfflineVsOnline_nuxt() {
    if(import.meta.env.PROD) {
        return "";
    }
    return "/owayo"
}
