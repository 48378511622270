/**
 * Created by Kai.Niklas on 21.04.2016.
 */
export class Adresse {
    ansprechpartner:string;
    verein:string;
    strasse:string;
    plz:string;
    ort:string;
    email:string;
    telefon:string;
    land:string;
    ustid:string;
    id:number|null;
    state:string;
    adresszusatz:string;
    vorname?:string;
    hatZollDisclaimer?:boolean;

    constructor(ansprechpartner, verein, strasse, plz, ort, email, telefon, land, id, state, ustid, adresszusatz="", hatZollDisclaimer=false) {
        this.ansprechpartner = ansprechpartner;
        this.verein = verein;
        this.strasse = strasse;
        this.plz = plz;
        this.ort = ort;
        this.email = email;
        this.telefon = telefon;
        this.land = land;
        this.id = id;
        this.ustid = "";// || ustid; //"" wird auch zu false ausgewertet!
        if (typeof(ustid) !== "undefined") {
            this.ustid = ustid;
        }
        this.state = "";// || ustid; //"" wird auch zu false ausgewertet!
        if (typeof(state) !== "undefined") {
            this.state = state;
        }
        this.adresszusatz = adresszusatz;
        this.hatZollDisclaimer = hatZollDisclaimer;
    }

    public static createAdresseFromServerData(adresseFromServer) {
        return new Adresse(
            adresseFromServer.ansprechpartner,
            adresseFromServer.verein,
            adresseFromServer.strasse,
            adresseFromServer.plz,
            adresseFromServer.ort,
            adresseFromServer.email,
            adresseFromServer.telefon,
            adresseFromServer.land,
            adresseFromServer.id,
            typeof(adresseFromServer.state) !== "undefined" ? adresseFromServer.state : "",
            typeof(adresseFromServer.ustid) !== "undefined" ? adresseFromServer.ustid : "",
            adresseFromServer.adresszusatz,
            typeof(adresseFromServer.zollDisclaimer) !== "undefined" && adresseFromServer.zollDisclaimer==="1"
        );
    }

}

export type KonfiguratorAdresseFromBackend = {
    ansprechpartner:string,
    vorname:string,
    verein:string,
    strasse:string,
    adresszusatz:string,
    plz:string,
    ort:string,
    land:string,
    email:string,
    telefon:string,
    ustid:string,
    state:string,
    id:number,
    visible: boolean,
    verwendetInAktuellemWarenkorb: boolean,
    geschlecht:string
}

export class AdressDaten {
    public ansprechpartner = "";
    public vorname = "";
    public street = "";
    public verein = "";
    public plz = "";
    public ort = "";
    public tel = "";
    public land = "";
    public ustid = "";
    public state = "";
    public zusatz = "";
    public geschlecht: string | number | string[] = "";
    public adressTyp = "";
    public isFirma = 0;
}
