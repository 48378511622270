import {ref} from "vue";
import gsap from "gsap";

export const confirmTextID = ref("");
export const canceltextID = ref("");

export class ConfirmOrConfirmCancelDialog {
    public static dialogState = ref({
        header: "",
        message: "",
        callbackOnConfirm: () => ConfirmOrConfirmCancelDialog.animateToOpenstate(false),
        callbackOnCancel: () => ConfirmOrConfirmCancelDialog.animateToOpenstate(false),
        displayType: "ConfirmCancel" as "ConfirmCancel" | "Confirm",
        shouldTranslateButtons: true
    });

    protected static setDialog(payload: {
        header: string,
        message: string,
        onOK: (() => void),
        onCancel: (() => void),
        displayType?: "ConfirmCancel" | "Confirm",
        shouldTranslateButtons?: boolean
    }) {
        ConfirmOrConfirmCancelDialog.dialogState.value.header = payload.header;
        ConfirmOrConfirmCancelDialog.dialogState.value.message = payload.message;
        ConfirmOrConfirmCancelDialog.dialogState.value.callbackOnConfirm = payload.onOK;
        ConfirmOrConfirmCancelDialog.dialogState.value.callbackOnCancel = payload.onCancel;
        ConfirmOrConfirmCancelDialog.dialogState.value.displayType = payload.displayType || "ConfirmCancel"
        ConfirmOrConfirmCancelDialog.dialogState.value.shouldTranslateButtons = (payload.shouldTranslateButtons !== undefined ? payload.shouldTranslateButtons : true)
    }

    protected static clearAndClose() {
        ConfirmOrConfirmCancelDialog.animateToOpenstate(false);
        ConfirmOrConfirmCancelDialog.dialogState.value.header = "";
        ConfirmOrConfirmCancelDialog.dialogState.value.message = "";
        ConfirmOrConfirmCancelDialog.dialogState.value.callbackOnConfirm = () => ConfirmOrConfirmCancelDialog.animateToOpenstate(false);
        ConfirmOrConfirmCancelDialog.dialogState.value.callbackOnCancel = () => ConfirmOrConfirmCancelDialog.animateToOpenstate(false);
        ConfirmOrConfirmCancelDialog.dialogState.value.displayType = "ConfirmCancel"
    }


    public static displayOKOKCancelDialog(header: string, message: string, displayType: "ConfirmCancel" | "Confirm", shouldTranslateButtonsInComponent: boolean = true): Promise<void> {

        return new Promise<void>((resolve, reject) => {
            ConfirmOrConfirmCancelDialog.setDialog({
                header: header,
                message: message,
                displayType: displayType,
                onOK: () => {
                    ConfirmOrConfirmCancelDialog.clearAndClose();
                    resolve()
                },
                onCancel: () => {
                    ConfirmOrConfirmCancelDialog.clearAndClose();
                    reject();
                },
                shouldTranslateButtons: shouldTranslateButtonsInComponent
            })
            ConfirmOrConfirmCancelDialog.animateToOpenstate(true);
        })
    }

    private static animateToOpenstate(newState: boolean) {
        gsap.to(".popup_twobutton.modal-wrapper", newState ? {opacity: 1, zIndex: 999999, duration: 0.4}
            : {opacity: 0, zIndex: -1, duration: 0.4})
    }
}

export class OKorOKCancelDialog extends ConfirmOrConfirmCancelDialog {
    public static displayOKOKCancelDialog(header: string, message: string, dialogType: "ConfirmCancel" | "Confirm") {
        confirmTextID.value = "konf_OK";
        canceltextID.value = "konf_Cancel";
        return ConfirmOrConfirmCancelDialog.displayOKOKCancelDialog(header, message, dialogType);
    }
}


export class YesNoDialog extends ConfirmOrConfirmCancelDialog {
    public static displayOKOKCancelDialog(header: string, message: string, dialogType: "ConfirmCancel" | "Confirm") {
        confirmTextID.value = "konf_yes";
        canceltextID.value = "konf_no";
        return ConfirmOrConfirmCancelDialog.displayOKOKCancelDialog(header, message, dialogType);
    }
}

export class ButtonDialog extends ConfirmOrConfirmCancelDialog {

    private static setButtonTexts(okID: string, cancelID: string) {
        confirmTextID.value = okID;
        canceltextID.value = cancelID;
    }

    public static displayTwoButtonDialog(header: string, message: string, okID: string, cancelID: string, shouldTranslateButtonsInComponent: boolean = true) {
        this.setButtonTexts(okID, cancelID);
        return ConfirmOrConfirmCancelDialog.displayOKOKCancelDialog(header, message, "ConfirmCancel", shouldTranslateButtonsInComponent);
    }

    public static displayOneButtonDialog(header: string, message: string, okID: string, cancelID: string) {
        this.setButtonTexts(okID, cancelID);
        return ConfirmOrConfirmCancelDialog.displayOKOKCancelDialog(header, message, "Confirm");
    }
}

export function displayConfirmationDialog(header: string, message: string): Promise<void> {
    return OKorOKCancelDialog.displayOKOKCancelDialog(header, message, "ConfirmCancel")
}

export function displayYesNoDialog(header: string, message: string): Promise<void> {
    return YesNoDialog.displayOKOKCancelDialog(header, message, "ConfirmCancel");
}

export function displayAcknowledgePopup(header: string, message: string): Promise<void> {
    return OKorOKCancelDialog.displayOKOKCancelDialog(header, message, "Confirm");
}

export function displayTwoButtonDialog(header: string, message: string, buttonText1: string, buttonText2: string, translateButtonsInComponent: boolean = true): Promise<void> {
    {
        return ButtonDialog.displayTwoButtonDialog(header, message, buttonText1, buttonText2, translateButtonsInComponent);
    }
}