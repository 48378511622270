import {Adresse} from "../../konfigurator_html/global/Adresse";
import {
    VersandInfoServerResponse,
    VersandOption,
    WarenkorbProduktionsarten,
    WarenkorbVersandTermine
} from "./warenkorbTypes";
import {LieferpositionDataStructure} from "../../konfigurator_html/global/LieferpositionDataStructure";



export type LieferpositionPreisModifikator = {
    betrag,
    code,
    enddatum,
    absolutOrRelative,
    produkt,
    sport,
    isProduktionsModifikator,
    isEntwurfModifikator,
    isArtikelModifikator,
    maxAnzahlExemplare,
    message,
    rabattcode
}

export enum Zahlarten {
    PAYPAL = 'PayPal',
    PAYPALPLUS = 'PayPalPlus',
    UEBERWEISUNG = 'Überweisung',
    SCHECK = 'Scheck',
    KREDITKARTE = 'Kreditkarte',
    KLARNA_IDEAL = 'Klarna über iDeal',
    KLARNA_SOFORT = 'Klarna Sofort',
    APPLEPAY = 'ApplePay',
    POSTFINANCE = 'PostFinance',
    IDEAL = 'iDeal',
    BANCONTACT = 'Bancontact',
    KLARNA_RECHNUNGSKAUF = 'Klarna Rechnungskauf',
    KREDITKARTE_BRAINTREE = 'Kreditkarte über Braintree',
    KREDITKARTE_LYRA = 'Kreditkarte über Lyra',
    PAYPAL_BRAINTREE = 'PayPal über Braintree',
    NOPAYMENT = "nopayment",
    TWINT = "Twint"
}


export class WarenkorbZahlart{
    public zahlart! : Zahlarten;
    public onlinePayment! :boolean;
    public onlineTemplate! : string;
    public kuerzel! : string;
    public contentID! : string;
    public descriptionID! : string;
    public logo! : string;

}

export class WarenkorbZahlarten {
    public zahlungsarten = [] as WarenkorbZahlart[];
    public akzeptierteKreditkarten = [];
    public ueberweisungsdaten;

    public constructor(zahlungsarten:WarenkorbZahlart[]=[], ueberweisungsdaten = null, akzeptierteKreditkarten = []) {
        this.zahlungsarten = zahlungsarten;
        this.ueberweisungsdaten = ueberweisungsdaten;
        this.akzeptierteKreditkarten = akzeptierteKreditkarten;
    }

    public getZahlart = (zahlart:Zahlarten)=>this.zahlungsarten.find(z=>z.zahlart === zahlart) || null;
    private addZahlungsarten(zahlungsarten=[])
    {
        const $this = this;
        zahlungsarten.forEach(function(item) {
            $this.zahlungsarten.push(WarenkorbZahlarten.getWarenkorbZahlart(item));
        });
    }

    private static getWarenkorbZahlart(item){
        const zahlart = new WarenkorbZahlart();
        zahlart.zahlart = item.zahlart;
        zahlart.onlinePayment = item.onlinePayment;
        zahlart.onlineTemplate = item.onlineTemplate;
        zahlart.kuerzel = item.kuerzel;
        return zahlart;
    }
}

export class WarenkorbRabatt {
    public code="";
    public message="";
    public ende=null;
    public absolutBetrag=0;
    public prozent=0;
    public valid = false;
    public kundenKonfiRabatt=0;
    public maxAnzahlExemplare=-1;
    public freeStandardShipping = false
}

export enum DESIGNCHECKARTEN {
    OWAYO = "owayo",
    SPEED = "speed",
    NONE = "kein"
}

export class OwayoWarenkorb {
    lieferadresse : Adresse;
    rechnungsadresse : Adresse;
    zahlart : Zahlarten;
    id! : number;
    kundenKonfiRabatt! : number;
    isEUStaat! : boolean;
    mwstFrei! : boolean;
    items : LieferpositionDataStructure[];
    termine : WarenkorbVersandTermine|null = null;
    liefertermin :Date|null=null;
    gewaehlteProduktionsart = WarenkorbProduktionsarten.STANDARD;
    moeglicheZahlarten : WarenkorbZahlarten|null = null;
    waehrung="";
    anzeigeWaehrung;
    anzeigeWaehrungSymbol;
    gesamtbetragNachModifizierern;
    versandOption = null;
    spediteur = "";
    versandart = "";
    moeglicheVersandarten:VersandOption[][]|null = null;
    gewaehlterDesigncheck = null as null|string;
    speedCheckAvailable = true;
    rabatt:WarenkorbRabatt;
    gesamtPreis=0;
    userChosePaymentAfterApproval=false;
    umsatzInEuro;
    wechselkurs = 1;
    designcheckPauschale = 0;
    datumBestellt :Date|null = null;
    anzeigeNamenSpediteur = null;
    inklusiveMwSt =true;
    anzeigeNameGewaehlterSpediteur! : string;
    Shop = null;
    versandkosten:VersandInfoServerResponse;
    produktionsartVersandartWarnungen: any;


    constructor() {
        this.lieferadresse = OwayoWarenkorb.getNulledOutAdresse();
        this.rechnungsadresse = OwayoWarenkorb.getNulledOutAdresse();
        this.items = [];
        this.rabatt = new WarenkorbRabatt();
        this.zahlart = Zahlarten.KREDITKARTE;
    }

    private static getNulledOutAdresse() {
        return new Adresse(null, null, null,null, null, null, null,null, null, null, null, "");
    }

    public static lieferPositionIsArtikel(item:LieferpositionDataStructure) {
        return item.typ === "Artikel";
    }

    public hasTshirt() {
        return this.items.some(i=>i.ordertype === "Oberteil veredelt");
    }

    public lieferAdresseIsTheSameAsRechnungsAdresse = ()=>this.lieferadresse?.id === this.rechnungsadresse?.id

    public hasRechnungsadresse() {
        return this.rechnungsadresse !== null && this.rechnungsadresse.id !== null;
    }
}
