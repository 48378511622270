import {EventVerteiler} from "./eventVerteiler";
import {InterframeMessage} from "./InterframeMessages";



export class InterframeCommunicator {

    public static initWindowListener() {

        window.addEventListener("message", (event)=>InterframeCommunicator.receiveMessage(event));
    }

    public static receiveMessage(event) {
        if(event.origin.replace(/:\d*$/, "") !== window.location.origin.replace(/:\d*$/, "")) {
            console.log("sorry, not accepting messages from "+event.origin);
            return;
        }
        let data = event.data;

        if(typeof(event.data) === "string") {
            try {
                data = JSON.parse(event.data);
            } catch (e) {
                return;
            }
        }

        if(data.messageText === undefined) {
            return;
        }
        EventVerteiler.receiveMessage(data);
    }
}

export function postMessageToParent(data:InterframeMessage|string) {

    const targetWindow = window.opener || window.parent
    if (typeof (data) === "string") {
        data = new InterframeMessage(data);
    }
    targetWindow.postMessage(JSON.stringify(data), window.location.origin);
}
