import {ErrorHandler} from "./ErrorHandler";
import axios from "axios";

import {IResourceManager} from "./resourcemanager/IResourceManager";

export class SessionController {
    private static errorHandler: ErrorHandler|null = null;

    public static setErrorHandler(errorHandler:ErrorHandler) {
        SessionController.errorHandler = errorHandler;
    }

    public static getInitialSession(callback: (data) => void, errorHandler: ErrorHandler, translate: (id, alternative?) => string) {
        axios.get("/konfigurator_php/sessiondata/session.php?get_session_status=1")
            .then(response => callback(response.data))
            .catch(() => SessionController.errorHandler?.handleError(
                    "ajaxFail",
                    "sessionManagement",
                    translate("konf_error_badSession", "Verzeihung, ich konnte die Sitzung nicht wiederherstellen.")
                )
            )
    }

    public static getUserBaseData(callback: (data) => void, errorHandler: ErrorHandler, resourceManager: IResourceManager, translate: (id, alternative?) => string) {
        resourceManager.registerResource({
            resourceName: "getUserData",
            failHandler: function () {
                errorHandler.handleError("ajaxFail", "sessionManagement", translate("konf_error_badSession", "Verzeihung, ich konnte die Sitzung nicht wiederherstellen."));
            },
            invalidatedWhen: "userLoaded"
        }).load({url: "/konfigurator_php/sessiondata/getUserBaseData.php"});

        resourceManager.createTask('')
            .when('getUserData', 'loaded')
            .discardAfterExecution()
            .thenDo(
                (data) => callback(data.getUserData)
            );

        /*$.ajax({
            type: "GET",
            url: "/konfigurator_php/sessiondata/getUserBaseData.php",
            dataType: 'json',
            success: function (data) {
                callback(data);
            },
            error: function () {
                errorHandler.handleError("ajaxFail", "sessionManagement", localizer.translate("konf_error_badSession", "Verzeihung, ich konnte die Sitzung nicht wiederherstellen."));
            }
        });*/
    }

}
