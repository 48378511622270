import {nextTick, Ref} from "vue";
import gsap from "gsap";

export function displayFadeoutMessageToUser(message:string) {
    MessageToDisplayContainer.addToDisplayQueue(message);
}

export class MessageToDisplayContainer {
    private static messagesToDisplay = [] as string[];
    private static isInitialized = false;
    private static displayOpen  =false;
    private static messageInTemplateRef: Ref<string>;

    public static init(messageInTemplateRef:Ref<string>) {
        MessageToDisplayContainer.messageInTemplateRef = messageInTemplateRef;
        if(this.isInitialized) {
            return;
        }
        MessageToDisplayContainer.messagesToDisplay = [];
        this.isInitialized = true;
    }

    public static addToDisplayQueue(message:string) {
        this.messagesToDisplay.push(message);
        if(!MessageToDisplayContainer.displayOpen) {
            MessageToDisplayContainer.displayFirstMessage();
        }
    }

    private static displayFirstMessage() {

        const message = this.messagesToDisplay.shift();
        if(!message) {
            return;
        }
        this.displayOpen = true;
        MessageToDisplayContainer.messageInTemplateRef.value = message;
        nextTick().then(()=>MessageToDisplayContainer.displayFeedbackPopup().then(()=>{
            MessageToDisplayContainer.displayOpen = false;
            MessageToDisplayContainer.displayFirstMessage();
        }));
    }

    private static options = {
        target : ".km__module_feedback",
        duration: 0.8
    }

    public static setOptions(target, duration){
        MessageToDisplayContainer.options.target = target;
        MessageToDisplayContainer.options.duration = duration;
    }


    private static displayFeedbackPopup() {
        return new Promise<void>(resolve=> {
            gsap.to(MessageToDisplayContainer.options.target, {
                zIndex: 999999,
                opacity: 1,
                duration: MessageToDisplayContainer.options.duration,
            }).then(() => gsap.to(MessageToDisplayContainer.options.target, {zIndex: -1, opacity: 0, duration: 1.0})).then(()=>resolve())
        })
    }
}