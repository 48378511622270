import axios from 'axios';
import {useShopInfos} from "~/store/shopInfosStore";
import {INTERFRAMEMESSAGES} from "@k/global/InterframeMessages";
import {EventVerteiler} from "@k/global/eventVerteiler";

export type TextGroup = {
    Part: string
    Inhalt: string
    value: string
}

export type ShopItemText = {
    NameInEntwurf: string,
    Part: string,
    Inhalt: string,
    value: string
}

export type FormerShopItem = {
    ShopUUID:string
    isInShop:boolean
}

export type ShopItem = {
    basePrice: number
    editableTexts: ShopItemText[]
    editableTextGroups: TextGroup[]
    featureStr: string
    gewaehlteGroesse: string
    isInShop: boolean
    isPersonalisierbar: boolean
    name: string
    positione: string
    possibleSizes: { name:string, size:string }[]
    previewPath: string
    produkt: string
    saveID: number
    shopPrice: string
    nameImShop: string
    features: { feature: string, featureInt: string }[]
    sport: string
    webproduct: string
    waehrungBasis: string
    aufpreisProzent: string
    aufpreisAbsolut: number
    waehrung: string
    auwiProdukt: string
    shopUUID: string
    preisliste: string
    staffelmenge: number
    tags: []
};

export type FilterTag = {
    category: string
    positione: string
    id: string
    tags: {tag: string, positione: string, id: string}[]
}

export const useShopItems = defineStore("shopItems", () => {

    const shopInfoStore = useShopInfos();
    const shopitems = ref({
        shopitems: [] as ShopItem[]
    });
    const filteredShopitems = ref({
        shopitems: [] as ShopItem[]
    });
    const possibleproducts = ref({
        shopitems: [] as ShopItem[]
    });
    const possibleproductsLoaded = ref(false);
    const formeritems = ref({formerShopItems:[] as FormerShopItem[]});

    const filterTags = ref({filterTagItems:[] as FilterTag[]});

    const selectedFilter = ref([] as FilterTag[]);

    // @ts-ignore
    const actions = {
        allShopItems: computed(() => shopitems.value),
        filteredShopitems: computed(() => filteredShopitems.value.shopitems.length>0 ? filteredShopitems.value : shopitems.value),
        possibleproductsLoaded: computed(()=>possibleproductsLoaded.value),
        allPossibleProducts: computed(() => possibleproducts.value),
        getFilterTags:computed(() => filterTags.value),
        getGewaehlteGroesse: computed(() => (saveID: number) => {
            return (shopitems.value.shopitems.find(item => item.saveID === saveID) as ShopItem).gewaehlteGroesse
        }),
        getSelectedFilters: computed(() => selectedFilter.value),
        getAllShopItems: (shopname: string) => {
            $fetch(
                `/shop_php/getAllShopItems.php?shopname=${shopname}&land=${shopInfoStore.getShopLand}&sprache=${shopInfoStore.getShopSprache}`
            ).then((response)=> {
                const parsed = JSON.parse(response);
                actions.setAllShopItems(parsed.itemsInShop);
                formeritems.value = parsed.formerShopItems

            });
        },
        setFormerShopItems(formeritemsToSet: { formerShopItems: FormerShopItem[] }) {
            formeritems.value = formeritemsToSet
        },

        allFormerItems: computed(()=>formeritems.value),
        getAllPossibleProducts: async (shopname: string) => {
            const id =  Math.floor(1e9 + Math.random() * 9e9).toString();
            const response = await $fetch(
                `/shop_php/getAllPossibleProducts.php?shopname=${shopname}&sprache=${shopInfoStore.getShopSprache}&land=${shopInfoStore.getShopLand}&it=${id}`
            );
            const parsed = JSON.parse(response);
            actions.setAllPossibleProducts(parsed.possibleProducts);
            actions.setFilterTags(parsed.filterTags);
            possibleproductsLoaded.value = true;
        },
        getAvailableFilterTags: async (shopname: string) => {
            const response = await $fetch(
                `/shop_php/getAvailableFilterTags.php?shopname=${shopname}&sprache=${shopInfoStore.getShopSprache}&land=${shopInfoStore.getShopLand}`
            );
            const parsed = JSON.parse(response);
            actions.setFilterTags(parsed.filterTags);
        },
        saveItemInWarenkorb: async (data: {item: ShopItem, size: string, shop: string, amount: number, farbvariantenSaveID: string}) => {
            await $fetch(
                `/shop_php/saveItemInWarenkorb.php?saveID=${data.farbvariantenSaveID}&shopPrice=${data.item.shopPrice}&size=${data.size}&shop=${data.shop}&amount=${data.amount}`
            );
            EventVerteiler.broadcast(INTERFRAMEMESSAGES.WARENKORB_CHANGED);
        },
        savePersonalisiertesItemInWarenkorb: async (data: { item: ShopItem, shop: string, amount : number, farbvariantenSaveID : number }) => {
            if (data.item.editableTextGroups.length > 0) {
                data.item.editableTexts.forEach(text => {
                    const group = data.item.editableTextGroups.filter(t => t.Inhalt === text.Inhalt);
                    if (group.length > 0) {
                        text.value = group[0].value;
                    }
                });
            }

            const transferObj: any = {};
            transferObj.shop = data.shop;
            transferObj.item = data.item;
            transferObj.amount = data.amount;

            transferObj.item.saveID = data.farbvariantenSaveID;

            await axios.post('/shop_php/savePersonalisiertesItemInWarenkorb.php', JSON.stringify(transferObj))
                .then(function (response) {
                    if (response.data === "success") {
                        EventVerteiler.broadcast(INTERFRAMEMESSAGES.WARENKORB_CHANGED);
                    }
                });
        },
        saveShopItemChanges: (data: { shopname: string, waehrung: string }) => {
            return new Promise<void>((resolve)=>{
                if (possibleproducts.value.shopitems.filter(item => (item.isInShop === true) && (parseFloat(item.aufpreisProzent)< 0)).length > 0) {
                    EventVerteiler.broadcast('produktMitNegativerMarge');
                    resolve();
                    return;
                }

                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.standardWaehrung = data.waehrung;
                transferObj.items = possibleproducts.value.shopitems.filter(item => item.isInShop === true);
                transferObj.sprache = shopInfoStore.getShopSprache;
                transferObj.land = shopInfoStore.getShopLand;
                $fetch('/shop_php/saveShopProdukte.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        if (response === "success") {
                            EventVerteiler.broadcast('produkteSpeichernErfolgreich');
                            actions.getAllShopItems(data.shopname);
                        }
                        resolve();
                    })
            });

        },
        setAllShopItems: (shopitemsToSet: { shopitems: ShopItem[] }) => {
            shopitems.value = shopitemsToSet;
            shopitems.value.shopitems = shopitems.value.shopitems.map(function (item) {
                if (item.features.length > 0) {
                    item.featureStr = (item.features.map(object => object.featureInt).join(', '));
                }
                if (item.waehrungBasis !== item.waehrung) {
                    item.shopPrice = (item.basePrice * (1 + (parseFloat(item.aufpreisProzent) / 100))).toFixed(2);
                }
                if (item.possibleSizes.length === 1) {
                    item.gewaehlteGroesse = item.possibleSizes[0].size;
                }
                if (item.editableTexts.length > 0) {
                    item.editableTextGroups = item.editableTexts.reduce((grouped, current) => {
                        const existingGroup = grouped.find(group => group.Inhalt === current.Inhalt);
                        if (existingGroup) {
                            existingGroup.Part += `, ${current.Part}`;
                        } else {
                            const newtextGroup: TextGroup = {
                                Part: current.Part,
                                Inhalt: current.Inhalt,
                                value: current.value
                            };
                            grouped.push(newtextGroup);
                        }

                        return grouped;
                    }, [] as TextGroup[]);
                }
                return item;
            });
        },
        setAllPossibleProducts: (possibleproductsToSet: { shopitems: ShopItem[] }) => {
            possibleproducts.value = possibleproductsToSet;
            possibleproducts.value.shopitems = possibleproducts.value.shopitems.map(function (item) {
                item.featureStr = (item.features.map(object => object.featureInt).join(', '));
                return item;
            });
        },
        setFilterTags: (filterTagsToSet: []) => {
            // @ts-ignore
            filterTags.value = filterTagsToSet;
        },
        updateShopPrice: (data: {saveID: number, value: string}) => {
            possibleproducts.value.shopitems = possibleproducts.value.shopitems.map(function (product) {
                if (product.saveID === data.saveID) {
                    product.aufpreisAbsolut = parseFloat(data.value) - product.basePrice;
                    product.aufpreisProzent = ((100 / product.basePrice) * (parseFloat(data.value) - product.basePrice)).toFixed(2);
                    product.shopPrice = data.value
                }
                return product;
            });
        },
        updateNameImShop: (data: {saveID: number, value: string}) => {
            possibleproducts.value.shopitems = possibleproducts.value.shopitems.map(function (product) {
                if (product.saveID === data.saveID) {
                    product.nameImShop = data.value
                }
                return product;
            });
        },
        updateInShopFlag: (data: {saveID: number, value: boolean}) => {
            possibleproducts.value.shopitems = possibleproducts.value.shopitems.map(function (product) {
                if (product.saveID === data.saveID) {
                    product.isInShop = data.value
                }
                return product;
            });
        },
        updatePositione: (data: {saveID: number, value: string}) => {
            possibleproducts.value.shopitems = possibleproducts.value.shopitems.map(function (product) {
                if (product.saveID === data.saveID) {
                    product.positione = data.value
                } else if (product.isInShop && parseInt(product.positione) >= parseInt(data.value)) {
                    product.positione = (parseInt(product.positione) + 1).toString();
                }
                return product;
            });

            possibleproducts.value.shopitems.sort((a, b) => parseInt(a.positione) - parseInt(b.positione));
        },
        setSizeForShopitem: (data: {saveID: number, value: string}) => {
            shopitems.value.shopitems = shopitems.value.shopitems.map(function (product) {
                if (product.saveID === data.saveID) {
                    product.gewaehlteGroesse = data.value
                }
                return product;
            });
        },
        setFilteredShopItems: () =>{
          filteredShopitems.value.shopitems =  shopitems.value.shopitems.filter((item) => {
              return selectedFilter.value.every(filter => {
                  // @ts-ignore
                  const itemCategoryGroup = item.tags.find(categoryGroup => categoryGroup.category === filter.category);
                  if (!itemCategoryGroup) {
                      return false;
                  }
                  // @ts-ignore
                  return filter.tags.some(tag => itemCategoryGroup.tags.some(t => t.name === tag.tag));
              });
          })
        },
        addCategory:(data: {shopname:string, category: string}) => {
            const newCategory = {category: data.category, positione: "9999", id: "-1", tags: []} as FilterTag;
            // @ts-ignore
            filterTags.value.push(newCategory);
            actions.saveCategoryForShop({shopname: data.shopname, category: data.category}).then();
        },
        updateCategory:(data: {shopname: string, categoryID: string, category: string}) => {
            // @ts-ignore
            filterTags.value = filterTags.value.map(function(filterCategory){
                if(filterCategory.id === data.categoryID) {
                    filterCategory.category = data.category;
                }
                return filterCategory;
            });
            actions.updateCategoryInBackend({shopname: data.shopname, categoryID: data.categoryID, category: data.category});
        },
        deleteCategory:(data: {shopname: string, categoryID: string}) => {
            // @ts-ignore
            filterTags.value = filterTags.value.filter(function(filterCategory){
                return filterCategory.id !== data.categoryID;
            });
            actions.deleteCategoryInBackend({shopname: data.shopname, categoryID: data.categoryID});
        },
        updateTag:(data: {shopname: string, categoryID: string, tagID: string, tag: string}) => {
            // @ts-ignore
            filterTags.value = filterTags.value.map(function(filterCategory){
                if(filterCategory.id === data.categoryID){
                    // @ts-ignore
                    filterCategory.tags.map(function (tagItem){
                        if(tagItem.id === data.tagID){
                            tagItem.tag = data.tag;
                        }
                        return tagItem;
                    })
                }
                return filterCategory;
            });
            actions.updateTagInBackend({shopname: data.shopname, tagID: data.tagID, tag: data.tag});
        },
        addTag:(data: {shopname:string, categoryID: string, tag: string}) => {
            // @ts-ignore
            const filterCategoryTags = filterTags.value.filter(ele => ele.id===data.categoryID)[0]["tags"];
            // @ts-ignore
            if(filterCategoryTags.filter(ele => data.tag === ele["tag"]).length < 1){
                filterCategoryTags.push({tag: data.tag, positione: "9999", id: "-1"});
                actions.saveTagForShop({shopname: data.shopname, categoryID: data.categoryID, tag: data.tag});
            }
        },
        deleteTag:(data: {shopname:string, categoryID: string, tagID: string}) => {
            // @ts-ignore
            filterTags.value = filterTags.value.map(function(filterCategory){
                if(filterCategory.id === data.categoryID){
                    // @ts-ignore
                    filterCategory.tags = filterCategory.tags.filter(function (tagItem){
                            return tagItem.id !== data.tagID;
                    })
                }
                return filterCategory;
            });
            actions.deleteTagInBackend({shopname: data.shopname, tagID: data.tagID});
        },
        addSelectedFilter:(data: {category: string, tag: string}) => {
            if(selectedFilter.value.filter(ele => ele.category === data.category).length < 1){
                // @ts-ignore
                selectedFilter.value.push({category: data.category, tags: []});
            }

            const selectedFilterCategory = selectedFilter.value.filter(ele => ele.category===data.category)[0];
            // @ts-ignore
            if(selectedFilterCategory.tags.filter(ele => data.tag === ele.name).length < 1){
                selectedFilterCategory.tags.push({tag: data.tag, positione: "9999", id: "-1"});
            }

            actions.setFilteredShopItems();
        },
        removeSelectedFilter:(data: {category: string, tag: string}) => {
            const selectedFilterCategory = selectedFilter.value.filter(ele => ele.category===data.category)[0];
            selectedFilterCategory.tags = selectedFilterCategory.tags.filter(item => item.tag !== data.tag);

            if(selectedFilterCategory.tags.length===0){
                selectedFilter.value = selectedFilter.value.filter(ele => ele.category!==data.category);
            }

            actions.setFilteredShopItems();
        },
        removeAllSelectedFilter:(() =>{
            selectedFilter.value = [];
            actions.setFilteredShopItems();
        }),
        updateCategoryInBackend: (data: { shopname:string, categoryID:string, category:string }) => {
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.categoryID = data.categoryID;
                transferObj.category = data.category;
                $fetch('/shop_php/updateCategoryInBackend.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        resolve();
                    })
            });
        },
        updateTagInBackend: (data: { shopname:string, tagID:string, tag:string }) => {
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.tagID = data.tagID;
                transferObj.tag = data.tag;
                $fetch('/shop_php/updateTagInBackend.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        resolve();
                    })
            });
        },
        deleteCategoryInBackend:(data: {shopname:string, categoryID:string}) =>{
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.categoryID = data.categoryID;
                $fetch('/shop_php/deleteCategoryInBackend.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        resolve();
                    })
            });
        },
        deleteTagInBackend: (data: {shopname:string, tagID:string}) => {
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.tagID = data.tagID;
                $fetch('/shop_php/deleteTagInBackend.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        resolve();
                    })
            });
        },
        saveCategoryForShop: (data: { shopname:string, category:string }) => {
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.category = data.category;
                $fetch('/shop_php/saveShopCategory.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        const parsed = JSON.parse(response);
                        // @ts-ignore
                        filterTags.value = filterTags.value.map(function(filterCategory){
                            if(filterCategory.category === parsed.category) {
                                filterCategory.id = parsed.id.toString();
                            }
                            return filterCategory;
                        });

                        resolve();
                    })
            });
        },
        saveTagForShop: (data: { shopname:string, categoryID:string, tag:string }) => {
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.categoryID = data.categoryID;
                transferObj.tag = data.tag;

                $fetch('/shop_php/saveShopTags.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        const parsed = JSON.parse(response);
                        // @ts-ignore
                        filterTags.value = filterTags.value.map(function(filterCategory){
                            if(filterCategory.id === parsed.categoryID){
                                // @ts-ignore
                                filterCategory.tags.map(function (tagItem){
                                    if(tagItem.tag === parsed.tag){
                                        tagItem.id = parsed.id.toString();
                                    }
                                    return tagItem;
                                })
                            }
                            return filterCategory;
                        });

                        resolve();
                    })
            });
        },
        saveTagsForSaveID: (data: { shopname: string, saveID: Number, filters: [] }) => {
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.saveID = data.saveID;
                transferObj.filters = data.filters;
                $fetch('/shop_php/saveShopProduktTags.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        actions.getAllPossibleProducts(data.shopname);
                        resolve();
                    })
            });
        },
        deleteEntwurf: (data: {saveID : string, shopname: string}) => {
            axios.post("/konfigurator_php/actionmodul/deleteEntwurf.php", new URLSearchParams({saveID: data.saveID}))
                .then(response=>{
                    actions.getAllPossibleProducts(data.shopname);
            });
        },
        createColorVariant: (data: {saveID: string, shopname: string, colorPalette: string, colorName: string} ) => {
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.saveID = data.saveID;
                transferObj.colorPalette = data.colorPalette;
                transferObj.colorName = data.colorName;
                $fetch('/shop_php/createFarbvariante.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        actions.getAllPossibleProducts(data.shopname).then();
                        resolve();
                    })
            });
        },
        updateColorVariant:(data: {shopname: string, saveID : string, farbvariantenID: number, addedToShop: boolean}) => {
            return new Promise<void>((resolve)=>{
                const transferObj: any = {};
                transferObj.shopname = data.shopname;
                transferObj.saveID = data.saveID;
                transferObj.farbvariantenID = data.farbvariantenID;
                transferObj.addedToShop = data.addedToShop;
                $fetch('/shop_php/updateFarbvariante.php', {body: transferObj, method: "POST"})
                    .then(function (response) {
                        // actions.getAllPossibleProducts(data.shopname);
                        resolve();
                    })
            });
        }
    }
    return actions;

})

