
import {ErrorHandler} from "@k/global/ErrorHandler";
import {EventVerteiler} from "@k/global/eventVerteiler";

export default class implements ErrorHandler {

    constructor() {
        EventVerteiler.setErrorHandler(this);
    }

    handleError(severity, happenedHere, messageToDisplay, callbackAfterMessageClose?) {

        // eslint-disable-next-line
        alert(severity + " Fehler: " + happenedHere + " meldet " + messageToDisplay);
        if(typeof (callbackAfterMessageClose) === "function") {
            callbackAfterMessageClose();
        }
    }

    handleErrorTranslateMessage(severity, happenedHere, messageToDisplayID, callbackAfterMessageClose?) {
        this.handleError(severity, happenedHere, messageToDisplayID, callbackAfterMessageClose);
    }

    public getAdressenLoadFailHandler() {
        return this.getAjaxFailHandler("loadAdressen", "konf_error_loadingAddresses");
    }

    public getAdressenDeleteFailHandler() {
        return this.getAjaxFailHandler("deleteAdresse", "konf_error_deletingAddress");
    }

    public getAjaxFailHandler(happenedHere, errorMessageID) {
        return ()=>this.handleErrorTranslateMessage("ajaxFail", happenedHere, errorMessageID);
    }

    translate(id, fallback?) {
        return fallback || id;
    }
    noop() {
        this.translate("");
    }
}