import {TabNames} from "@km/data/tabs";
import {UserModuleNames} from "@km/components/user/UserModulesAnimations";
import {EnumDictionary} from "@k/js/delme_globals"

export enum OverlayNames {
    cockpit = "cockpit",
    drafts = "drafts",
    designcheck = "designcheck",
    designwish = "designwish",
    deliverytime = "deliverytime",
    contact = "contact",
    contactform ="contactform",
    socialshare = "socialshare",
    rdydesign = "rdydesign",
    farbvorschlag = "farbvorschlag",
    productsize="productsize",
    exemplarliste="exemplarliste",
    expressanfrage="expressanfrage",
    lieferzeitAnfrage="lieferzeitAnfrage",
    produktliste = "produktliste",
    produktlisteFeatures ="produktlisteFeatures"
}

function getHighestOpenTabName(tabs:{DESIGNSTAB:boolean, COLORSTAB:boolean, TEXTTAB:boolean, LOGOTAB:boolean}) {
    if (tabs.DESIGNSTAB) {
        return TabNames.DESIGNS;
    }
    if (tabs.COLORSTAB) {
        return TabNames.COLORS;
    }
    if (tabs.TEXTTAB) {
        return TabNames.TEXT;
    }
    if (tabs.LOGOTAB) {
        return TabNames.LOGO;
    }
    return TabNames.NONE;
}

function openUserLayer(userLayers, layername:UserModuleNames) {
    userLayers.orders = layername === UserModuleNames.orders;
    userLayers.account = layername === UserModuleNames.account;
    userLayers.adressen = layername === UserModuleNames.adressen;
    userLayers.reset_password = layername === UserModuleNames.reset_password;
    userLayers.change_email = layername === UserModuleNames.change_email;
    userLayers.login = layername === UserModuleNames.login;
    userLayers.registration = layername === UserModuleNames.registration;
    userLayers.forgot_username = layername === UserModuleNames.forgot_username;
    userLayers.forgot_password = layername === UserModuleNames.forgot_password;
    userLayers.add_address = layername === UserModuleNames.add_address;
    userLayers.edit_address = layername === UserModuleNames.edit_address;
}

function getOpenUserLayer(userLayers):UserModuleNames {
    if(userLayers.orders) {
        return UserModuleNames.orders
    }
    if(userLayers.account) {
        return UserModuleNames.account
    }
    if(userLayers.adressen) {
        return UserModuleNames.adressen
    }
    if(userLayers.reset_password) {
        return UserModuleNames.reset_password
    }
    if(userLayers.change_email) {
        return UserModuleNames.change_email
    }
    if(userLayers.login) {
        return UserModuleNames.login
    }
    if(userLayers.registration) {
        return UserModuleNames.registration
    }
    if(userLayers.forgot_username) {
        return UserModuleNames.forgot_username
    }
    if(userLayers.forgot_password) {
        return UserModuleNames.forgot_password
    }
    if(userLayers.add_address) {
        return UserModuleNames.add_address
    }
    if(userLayers.edit_address) {
        return UserModuleNames.edit_address
    }
    return UserModuleNames.closed
}

export default {
    state: () => ({
        tabs: {
            DESIGNSTAB: false,
            COLORSTAB: false,
            TEXTTAB: false,
            LOGOTAB: false,
        },

        overlays: {
            drafts: false,
            cockpit: false,
            designcheck: false,
            designwish: false,
            deliverytime: false,
            contact: false,
            contactform: false,
            socialshare: false,
            rdydesign: false,
            farbvorschlag: false,
            productsize:false,
            exemplarliste:false,
            expressanfrage:false,
            lieferzeitAnfrage:false,
            [OverlayNames.produktliste]:false,
            [OverlayNames.produktlisteFeatures]:false,
        } as EnumDictionary<OverlayNames, boolean>,
        //navi: false,
        user: {
            orders: false,
            account: false,
            adressen: false,
            reset_password: false,
            change_email: false,
            login: false,
            registration: false,
            forgot_username: false,
            forgot_password: false,
            add_address: false,
            edit_address: false,
        },
        artikelView:false
    }),
    getters: {
        DESIGNSTABisOpen: state => state.tabs.DESIGNSTAB,
        COLORSTABisOpen: state => state.tabs.COLORSTAB,
        TEXTTABisOpen: state => state.tabs.TEXTTAB,
        LOGOTABisOpen: state => state.tabs.LOGOTAB,
        getOpenTabName: state => getHighestOpenTabName(state.tabs),
        cockpitIsOpen: state => state.overlays.cockpit,
        draftsoverlayIsOpen: state => state.overlays.drafts,
        designcheckoverlayIsOpen: state => state.overlays.designcheck,
        designwishoverlayIsOpen: state => state.overlays.designwish,
        deliverytimeoverlayIsOpen: state => state.overlays.deliverytime,
        expressanfrageOverlayIsOpen:state=>state.overlays.expressanfrage,
        contactoverlayIsOpen: state => state.overlays.contact,
        contactformoverlayIsOpen: state => state.overlays.contactform,
        socialshareoverlayIsOpen: state => state.overlays.socialshare,
        rdydesignoverlayIsOpen: state => state.overlays.rdydesign,
        farbvorschlagoverlayIsOpen: state => state.overlays.farbvorschlag,
        exemplarListeIsOpen:state=>state.overlays.exemplarliste,
        getOpenUserModules: state => getOpenUserLayer(state.user),
        productsizeIsOpen:state=>state.overlays.productsize,
        lieferzeitAnfrageIsOpen:state=>state.overlays.lieferzeitAnfrage,
        artikelViewIsActive:state=>state.artikelView,
        produktListeIsOpen:state=>state.overlays[OverlayNames.produktliste],
        produktListeFeaturesIsOpen:state=>state.overlays[OverlayNames.produktlisteFeatures],

    },
    mutations: {
        SET_TAB_OPEN(state, tabname: TabNames) {
            state.tabs.DESIGNSTAB = tabname === TabNames.DESIGNS;
            state.tabs.COLORSTAB = tabname === TabNames.COLORS;
            state.tabs.TEXTTAB = tabname === TabNames.TEXT;
            state.tabs.LOGOTAB = tabname === TabNames.LOGO;
        },

        OPEN_ARTIKELVIEW(state) {
            state.artikelView = true;
        },
        CLOSE_ARTIKELVIEW(state) {
            state.artikelView = false;
        },
        SET_OVERLAY_OPEN(state, layername: OverlayNames) {
            (state.overlays)[layername] = true;
            window.scroll(0,0);
        },
        SET_OVERLAY_CLOSED(state, layername: OverlayNames) {
            (state.overlays)[layername] = false;
        },
        CLOSE_ALL_COCKPIT_OVERLAYS(state) {
            state.overlays.cockpit = state.overlays.drafts = state.overlays.exemplarliste = state.overlays.socialshare = false;
        },
        SET_USER_ORDERS_OVERLAY(state, openState: boolean) {
            state.user.orders = openState
        },

        SET_ACCOUNT_OVERLAY_STATUS(state, openState: boolean) {
            state.user.account = openState;
        },
        SET_ADRESSEN_OVERLAY_STATUS(state, openState: boolean) {
            state.user.adressen = openState;
        },
        SET_RESET_PASSWORD_STATE(state, openState: boolean) {
            state.user.reset_password = openState;
        },
        SET_CHANGE_EMAIL_STATE(state, openState: boolean) {
            state.user.change_email = openState;
        },
        SET_REGISTRATION_STATE(state, openState: boolean) {
            state.user.registration = openState;
        },

        CLOSE_USERLAYER(state, layername: UserModuleNames) {
            if (state.user[layername] === undefined) {
                return;
            }
            state.user[layername] = false;
            //state.user.myAccount = false;
        },
        OPEN_USERLAYER(state, layername: UserModuleNames) {
            openUserLayer(state.user, layername);
        },
        CLOSE_ALL_USERLAYER(state){
            for (const layer in state.user) {
                state.user[layer] = false;
            }
}
    }
}
