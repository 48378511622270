import {OwayoWarenkorb} from "../../shoppingcart/js/Warenkorb";
import {Adresse} from "./Adresse";
import {EntwurfListenEintragFromBackend} from "../js/databaseInterfaceClasses";

export class  OwayoUser {
    public userName:string;
    public warenkorb : OwayoWarenkorb;
    public email:string;
    public telefon:string;
    public ansprechpartner:string;
    public adressen:Adresse[];
    public loggedIn:boolean;
    public derivedUserDataPath = "";
    public aiLogoCredits;
    public aiLogoCreditsUsed;

    constructor(userName, warenkorb:OwayoWarenkorb) {
        this.userName = userName.replace("\\'", "'");
        this.email = "";
        this.telefon = "";
        this.ansprechpartner = "";
        this.warenkorb = warenkorb;
        this.adressen = [];
        this.loggedIn = false;
    }
}

export class KonfiUser extends OwayoUser {
    public entwuerfe : EntwurfListenEintragFromBackend[];

    constructor(userName:string, warenkorb:OwayoWarenkorb) {
        super(userName, warenkorb);
        this.entwuerfe = [];
    }
}
