import { reactive, ref, readonly, InjectionKey, provide, inject, Ref, onUnmounted, computed } from 'vue'

interface TabData {
    name: string,
}

export const tabsInjectionKey = Symbol('tabs') as InjectionKey<{
    registerTab: (identifier: symbol, tabData: TabData) => void,
    deregisterTab: (identifier: symbol) => void
    activeTab: Readonly<Ref<symbol>>,
}>

export const useTabs = () => {
    const tabs = reactive(new Map<symbol, TabData>())
    const registerTab = (identifier: symbol, tabData: TabData) => {
        tabs.set(identifier, tabData)
    }

    const deregisterTab = (identifier: symbol) => {
        tabs.delete(identifier)
    }

    const activeTab = ref<symbol>()
    const activeTabStr = ref("");


    provide(tabsInjectionKey, {
        registerTab,
        deregisterTab,
        activeTab: readonly(activeTab),
    })

    const setActiveTab = (identifier: symbol) => {
        activeTab.value = identifier;
        activeTabStr.value = typeof(activeTab.value)!=="undefined" ? activeTab.value.description : "";
    }

    return {
        tabs: readonly(tabs),
        setActiveTab,
        activeTabStr,
        activeTab
    }
}

export const useTab = (tabData: TabData) => {
    const tabsInjection = inject(tabsInjectionKey)

    if (!tabsInjection) {
        throw new Error('Tabs were not provided')
    }

    const { registerTab, deregisterTab, activeTab } = tabsInjection

    const tabSymbol = Symbol(tabData.name)

    registerTab(tabSymbol, tabData)

    onUnmounted(() => {
        deregisterTab(tabSymbol)
    })

    const isActive = computed(() => (
        activeTab.value === tabSymbol
    ))

    return {
        isActive,
    }
}