
export type AuwiNormalizedZielspracheContainer ={
    auwi:string;
    webNormalized:string;
    webInZielsprache:string;
}


export type GetParametersDecodedProductInfo = {
    sport:AuwiNormalizedZielspracheContainer,
    product:AuwiNormalizedZielspracheContainer,
    ordertype:string,
    ansicht:string,
    schnitt:string,
    design:string,
    readyDesign:string,
    geschlecht:string,
    DVSchnitt:string,
    featureObjects:Array<any>
}
export type KKFunktionalitaet = {
    Funktionalitaet:string,
    blacklisted:boolean,
}

export type GetParametersDecoded =
{

    kundenKonfi,
    lang,
    land,
    login,
    owayoDirectLogin,
    saveId,
    HeaderLogoHTMLString,
    KundenKonfiRabatt,
    rabattCode,
    funktionalitaeten:KKFunktionalitaet[],
    locale,
    einzelExemplarImagename,
    domain,
    specificFeatures,
    session_count,
    infoEmailAdresse,
    isWarenkorbEdit:boolean,
    KundenKonfiEmail,
    fbPostID,
    showFeatures,
    takePreviews;
    productInfo:GetParametersDecodedProductInfo,
    sportFuerProduktauswahl:AuwiNormalizedZielspracheContainer,
    isOfflineVersion:boolean
    motiv:string|null;
    productcolor: string | null;
}





export interface GoogleTracker {
    _getTracker(TRACKERID) : GoogleTrackerInstance;
    _anonymizeIp();
}

export interface GoogleTrackerInstance {
    _trackPageview(TRACKERPAGESTRING);
}
export class Mesh {
    isUVCoordinateInFaceUV
}

export class WidthHeight {
    public width;
    public height;

    public constructor(width, height) {
        this.width = width;
        this.height = height;
    }
}

export type XY = {
    x:number;
    y:number;
}

export class Vector2 {
    x  = NaN;
    y  = NaN;

    constructor(x = NaN, y = NaN) {
        this.x =x;
        this.y = y;
    }

    public apply(func):Vector2 {
        return new Vector2(func(this.x), func(this.y));
    }

    public static max(v:Vector2, w:Vector2) {
        return new Vector2(Math.max(v.x, w.x), Math.max(v.y, w.y));
    }

    public static componentAdd(a:Vector2, b:Vector2) {
        return new Vector2(a.x + b.x, a.y + b.y)
    }
    public static componentSubtract(a:Vector2, b:Vector2) {
        return new Vector2(a.x - b.x, a.y - b.y)
    }
    public clone():Vector2 {
        return new Vector2(this.x, this.y);
    }
    public static multiplyComponents = (v:Vector2, w:Vector2)=> {
        return new Vector2(v.x * w.x, v.y * w.y);
    }
}

export class Strecke {
    public start:Vector2;
    public end:Vector2;

    public constructor(start:Vector2, end:Vector2) {
        this.end = end;
        this.start = start;
    }

    public asArray() {
        return [this.start, this.end];
    }
}

export class Vector2WithUV extends Vector2 {
    u  = NaN;
    v  = NaN;

    constructor(x = NaN, y = NaN, u = NaN, v = NaN) {
        super(x, y);
        this.u = u;
        this.v = v;
    }

    public apply(func):Vector2WithUV {
        super.apply(func);
        this.u = func(this.u);
        this.v = func(this.v);
        return this;
    }
}

// declare namespace THREE {
//     export class AnaglyphEffect {
//         constructor(params);
//     }
//     export class TextGeometry {
//         constructor(message : string, args:any);
//     }
//     export class LBVH {
//         static createTree(child);
//     }
// }

export interface Window {
    webkitURL;
    fbAsyncInit;
    WebGLRenderingContext;
}

export interface Document {
    mozFullScreenElement;
    msFullscreenElement;
}

export class ContentWithLevel {
    public content;
    public level;

    public constructor(content, level) {
        this.content = content;
        this.level = level;
    }
}
export type StringDictionary<T> = {
    [key:string]:T
}


export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

