import type {LokalisierungsProvider} from "../global/Localizer";
import type {ErrorHandler} from "../global/ErrorHandler";
import type {Controller} from "./controller";
import type {GoogleTracker} from "../global/googleTrackerObject";
import type {IframeViewController} from "../global/iframeViewController";
import type {OwayoJSKonfiViewer} from "./owayoJSViewerWithSelObs";
import type {ProductController} from "./produktController";
import type {LoginAdditionalFeaturesController} from "./loginAdditionalFeaturesController";
import type {HistoryController} from "./history/historyController";
import type {ActionController} from "./actionController";
import type {RahmenTabs} from "./rahmenTabs";
import type {ActionViewComponent} from "./actionViewComponent";
import type {RahmentabsToolbox} from "./rahmentabsModules/rahmentabsToolbox";
import type {RahmentabsLogoEditReiter} from "./rahmentabsModules/rahmentabsLogoEditReiter";
import type {DesignUebersicht} from "./rahmentabsModules/designuebersicht";
import type {RahmentabsTextReiter} from "./rahmentabsModules/rahmentabsTextReiter";
import type {PatternViewComponent} from "./pattern/patternViewComponent";
import type {PatternFactory} from "./pattern/patternFactory";
import type {RahmenTabsColorpickerFunctions} from "./rahmentabsModules/rahmenTabsColorPicker";
import type {FontHandler} from "./fontHandler";
import type {RahmenTabsLogoFarbenModul} from "./rahmentabsModules/rahmenTabsLogoFarbenModul";
import type {RahmenTabsDialogDisplayer} from "./rahmentabsModules/rahmenTabsDialogDisplayer";
import type {RahmenTabsHintBox} from "./rahmentabsModules/rahmenTabsHintBox";
import type {EntwuerfeFenster} from "./rahmentabsModules/rahmentabsEntwurfeFenster";

import type {RahmenTabsTabSwitcher} from "./rahmentabsModules/rahmenTabsTabSwitcher";
import type {RahmentabsLogoReiter} from "./rahmentabsModules/rahmenTabsLogoReiter";
import type {AccountController} from "./account/accountController";
import type {JQueryAjaxConnector} from "../global/ajaxConnector";
import type {GetParametersDecoded} from "./delme_globals";
import type {ErrorLog} from "./errorlogObject";
import type {StockLogoStore} from "./stocklogos/stocklogoController";
import type {SelectableObjectInsertionPointFinder} from "../global/SelObInsertionPointFinder";
import type {IResourceManager} from "../global/resourcemanager/IResourceManager";
import {setGlobalVar} from "../global/globalUtils";
import {UserManagementConnectorRoutes} from "../global/UserManagementConnectorRoutes";
import {RahmentabsRDYDesigns} from "./rahmentabsModules/rahmentabsRDYDesigns";
import {LoopTracker} from "@k/global/loopTracker";

class FrontEndModulesHolder {
    public loginController!: AccountController;
    public designUebersicht!: DesignUebersicht;
    public rahmentabsTextReiter!: RahmentabsTextReiter;
    public patternViewComponent!: PatternViewComponent;
    public patternController!: PatternFactory;
    public colorPicker!: RahmenTabsColorpickerFunctions;
    public fontHandler!: FontHandler;
    public chromoLogoModul!: RahmenTabsLogoFarbenModul;
    public dialogDisplayer!: RahmenTabsDialogDisplayer;
    public hintbox!: RahmenTabsHintBox;
    public entwuerfeFenster!: EntwuerfeFenster;
    public tabswitcher!: RahmenTabsTabSwitcher;
    public logoPoolReiter!: RahmentabsLogoReiter;
    public toolbox!: RahmentabsToolbox;
    public selObReiter!: RahmentabsLogoEditReiter;
    public actionViewComponent!: ActionViewComponent;
    public rdyPopup: RahmentabsRDYDesigns;
}


export class globalsObject {
    public static resourceManager: IResourceManager;
    public static errorHandler: ErrorHandler;
    public static localizer: LokalisierungsProvider;
    public static controller: Controller;
    public static ajaxConnector: JQueryAjaxConnector;
    public static googleTracker: GoogleTracker;
    public static loopTracker: LoopTracker;
    public static iframeViewController: IframeViewController;
    public static owayoJSViewer: OwayoJSKonfiViewer;
    public static productController: ProductController;
    public static frontendModules = new FrontEndModulesHolder();
    public static loginAdditionalFeaturesController: LoginAdditionalFeaturesController;
    public static historyController: HistoryController;
    public static actionController: ActionController;

    public static rahmenTabs: RahmenTabs;
    public static infoFromGetParameters:GetParametersDecoded;
    public static errorLog:ErrorLog;
    public static stockLogoStore:StockLogoStore;
    public static selObInsertionFinder: SelectableObjectInsertionPointFinder;
    static usermanagementConnector: UserManagementConnectorRoutes;
}

export default globalsObject;

export function translate(id:string, alternative?:string) {
    return globalsObject.localizer.translate(id, alternative);
}

export function trackPageview(url:string) {
    return globalsObject.googleTracker.trackPageview(url)
}

export function getEmailFromActiveUser():string{
    let email = globalsObject.controller.model.user.getEmail();
    return email || "";
}

export function handleError(severity:string, happenedHere:string, messageToDisplay:string, callbackAfterMessageClose?:()=>void) {
    globalsObject.errorHandler.handleError(severity, happenedHere, messageToDisplay, callbackAfterMessageClose)
}

setGlobalVar("trackPageview", trackPageview);
setGlobalVar("getEmailFromActiveUser", getEmailFromActiveUser);



