
export enum UserModuleNames {
    closed = "closed",
    account = "account",
    adressen = "adressen",
    change_email = "change_email",
    login = "login",
    registration = "registration",
    orders = "orders",
    reset_password = "reset_password",
    forgot_username = "forgot_username",
    forgot_password = "forgot_password",
    add_address = "add_address",
    edit_address = "edit_address",
    logout = "logout"
}
