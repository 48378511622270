
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_930K4P9kSURto9L_45neWjchAnZlq_45dplgBlUbd_45zX5aAcwMeta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/[id].vue?macro=true";
import { default as draftsiWax1D6pDTnZcUTAPrQvIrUkIAJhofb72OMezUQfTf0Meta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/drafts.vue?macro=true";
import { default as impressumqyyCuxFV_OhGx7d9xD1vH7s6KsaPSPwtgnH92xjcv_45sMeta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/impressum.vue?macro=true";
import { default as indexFjK_1WC6x2UcD0GZgFxbx3vOuv0Yh4R29Mt0WLU2AJ4Meta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/index.vue?macro=true";
import { default as summaryxX7vmDDViztnJl2p_45gcH9_45uw1DOUOjA6asH6itdWVjQMeta } from "E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/summary.vue?macro=true";
import { default as indexPELp8qrrHk623pHvXXQLysNaxa_I2cSbWZgr31EF9JUMeta } from "E:/www/shop_nuxt/pages/index.vue?macro=true";
export default [
  {
    name: "shopdirectoryLocalized-shopname-id",
    path: "/:shopdirectoryLocalized()/:shopname()/:id()",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/[id].vue")
  },
  {
    name: "shopdirectoryLocalized-shopname-drafts",
    path: "/:shopdirectoryLocalized()/:shopname()/drafts",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/drafts.vue")
  },
  {
    name: "shopdirectoryLocalized-shopname-impressum",
    path: "/:shopdirectoryLocalized()/:shopname()/impressum",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/impressum.vue")
  },
  {
    name: "shopdirectoryLocalized-shopname",
    path: "/:shopdirectoryLocalized()/:shopname()",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/index.vue")
  },
  {
    name: "shopdirectoryLocalized-shopname-summary",
    path: "/:shopdirectoryLocalized()/:shopname()/summary",
    component: () => import("E:/www/shop_nuxt/pages/[shopdirectoryLocalized]/[shopname]/summary.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/www/shop_nuxt/pages/index.vue")
  }
]