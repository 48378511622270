import {OwayoWarenkorb} from "../../shoppingcart/js/Warenkorb";
import {KonfiUser} from "../global/OwayoUser";
import {AdressenPersistance, AdressenPersistancePromise} from "../global/AdressenPersistance";
import globalsObject, {translate} from "./globalsObject";
import {EventVerteiler} from "../global/eventVerteiler";
import {EntwurfListenEintragFromBackend} from "./databaseInterfaceClasses";

export interface IUserWrapper {
    createNewUser(username:string, warenkorb?:OwayoWarenkorb|null);
    setLoggedIn(isLoggedIn:boolean);
    getUserName();
    setAnsprechPartner(ansprechpartner:string);
    setEmail(email:string);
    setTelefon(tel:string);
    setAdressen(adressen);
    loadAdressen();
    loadAnzWarenkorbPositionen();
    loadKontaktdaten();
    getEntwuerfe():EntwurfListenEintragFromBackend[];
    addEntwurf(entwurf:EntwurfListenEintragFromBackend)
    setEntwuerfe(entwuerfe):void;
    getDerivedUserDataPath();
    isLoggedIn():boolean;
    setDerivedUserDataPath(derivedUserdataPath:string),
    getEmail(): string;
    userLoggedOut(newTempName:string);
    userLoggedIn(username:string, loginData:{ansprechpartner, email, telefon});
    loadUserData();
    userRegistrationSuccessful(username:string);
    setUserFromInitialSession(username:string, loggedIn:boolean)
    incrementAiLogoCreditsUsed();
    getRemainingAILogoCredits();
}


export abstract class UserWrapperBase {

    abstract loadAdressen();
    abstract loadAnzWarenkorbPositionen();
    abstract loadKontaktdaten();
    abstract createNewUser(username: string, warenkorb?: OwayoWarenkorb | null);
    abstract setLoggedIn(isLoggedIn: boolean);
    abstract setAnsprechPartner(ansprechpartner: string);
    abstract setTelefon(tel: string);
    abstract setEmail(email: string);

    loadUserData() {
        this.loadAdressen();
        this.loadAnzWarenkorbPositionen();
        this.loadKontaktdaten();
        this.loadLogos();
        EventVerteiler.broadcast("initFontsEvent", {language: "de"});
    }

    setUserFromInitialSession(username: string, loggedIn: boolean) {
        this.createNewUser(username, new OwayoWarenkorb());
        this.setLoggedIn(loggedIn);
        this.loadUserData();
    }

    userLoggedIn(username: string, loginData: { ansprechpartner; email; telefon }) {
        this.createNewUser(username);
        this.setLoggedIn(true);
        this.setAnsprechPartner(loginData.ansprechpartner);
        this.setTelefon(loginData.telefon);
        this.setEmail(loginData.email);
        this.loadUserData();
    }

    userLoggedOut(newTempName: string) {
        this.createNewUser(newTempName, new OwayoWarenkorb());
        this.setLoggedIn(false);
        this.loadUserData();
    }

    userRegistrationSuccessful(username: string) {
        this.createNewUser(username);
        this.setLoggedIn(true);
        this.loadUserData();
    }

    private loadLogos() {
        EventVerteiler.broadcast("loadLogoPoolEvent");
    }
}

export class UserWrapperKonfi extends UserWrapperBase implements IUserWrapper{
    private user:KonfiUser;

    public constructor(username, warenkorb:OwayoWarenkorb) {
        super();
        this.user = new KonfiUser(username, warenkorb);
    }

    createNewUser(username: string, warenkorb: OwayoWarenkorb|null = null) {
        this.user = new KonfiUser(username, warenkorb === null ? this.user.warenkorb : warenkorb);
    }

    setLoggedIn = (isLoggedIn: boolean) => this.user.loggedIn = isLoggedIn;
    getUserName = () => this.user.userName;
    setAnsprechPartner = (ansprechpartner: string) => this.user.ansprechpartner = ansprechpartner;
    setEmail = (email: string) => this.user.email = email;
    setTelefon = (tel: string) => this.user.telefon = tel;
    setAdressen = adressen => this.user.adressen = adressen;
    getEntwuerfe = () => this.user.entwuerfe;
    setEntwuerfe = (entwuerfe) => this.user.entwuerfe = entwuerfe;
    getDerivedUserDataPath = () => this.user.derivedUserDataPath;
    isLoggedIn = (): boolean => this.user.loggedIn;
    setDerivedUserDataPath = (derivedUserdataPath: string) => this.user.derivedUserDataPath = derivedUserdataPath;
    getEmail = (): string => this.user.email;

    loadAdressen = () => AdressenPersistancePromise.loadAdressen()
        .then((response) => this.setAdressen(response.adressen))
        .catch(()=>globalsObject.errorHandler.getAdressenLoadFailHandler());

    loadAnzWarenkorbPositionen() {
        $.ajax({
            type: "GET",
            url: "/konfigurator_php/actionmodul/getAnzWarenkorb.php?username=" + this.user.userName,
            dataType: 'json'
        })
            .fail(function () {//request, status) {
                globalsObject.errorHandler.handleError("ajaxFail", "loadWarenkorb",
                    translate("konf_error_loadingWarenkorb", "Ein Fehler ist beim Laden des Warenkorb aufgetreten."));
            })
            .done(function (response) {
                EventVerteiler.broadcast("setAnzahlWarenkorbEvent", response.Anzahl);
            });
    }

    loadKontaktdaten() {
        const $this = this;
        $.get("/konfigurator_php/sessiondata/getKontaktdaten.php", function (data) {
            if (data === false) {
                return;
            }
            $this.user.email = data.email;
            $this.user.ansprechpartner = data.ansprechpartner;
            $this.user.telefon = data.tel;
            $this.user.aiLogoCredits = parseInt(data.aiLogoCredits);
            $this.user.aiLogoCreditsUsed = parseInt(data.aiLogoCreditsUsed);
        }, "json");
    }
    addEntwurf(entwurf: EntwurfListenEintragFromBackend) {
        this.user.entwuerfe.unshift(entwurf);
    }

    incrementAiLogoCreditsUsed() {
        this.user.aiLogoCreditsUsed++;
    }

    getRemainingAILogoCredits():number {
        return this.user.aiLogoCredits - this.user.aiLogoCreditsUsed
    }
}
