import {ShopAdresse, ShopZahlungsverbindung, UserShop} from "@global/js/UserShop";
import axios from "axios";
import type {Ref} from "vue";
import {computed, ref} from "vue";
import {defineStore} from "pinia";


export const useShopStore = defineStore("shopStore", ()=>{
        const userShop = ref(null) as Ref<UserShop|null>;
        const userShopAdresse = ref(null) as Ref<ShopAdresse|null>;
        const userShopZahlungsDaten = ref(null) as Ref<ShopZahlungsverbindung|null>;
        const userFreigegebenFuerShop = ref(false);
        const loaded = ref(false);

        const actions = {
            getUserShopname:computed(()=>userShop.value?.shopName),
            getUserShopLogoPath:computed(()=> userShop.value?.shoplogopath),
            getUserShopStatus:computed(()=>userShop.value?.status),
            getUserShopTheme:computed(()=>userShop.value?.theme),
            getPossibleThemes:computed(()=>userShop.value?.possibleThemes),
            getHatFreigabeFuerShop:computed(()=>userFreigegebenFuerShop.value),
            getUserShopEmail:computed(()=>userShop.value?.email),
            getUserShopTelefon:computed(()=>userShop.value?.telefon),
            getUserShopAdresse:computed(()=>userShopAdresse.value),
            getIsFirmaOrPerson:computed(()=>userShop.value?.isFirmaOrJristischePerson),
            getSteuerstatus:computed(()=>userShop.value?.steuerstatus),
            getShopUStID:computed(()=>userShop.value?.ustid),
            getZahlungsverbindung:computed(()=>userShop.value?.zahlungsverbindung),
            getZahlungsverbindungKontoinhaber:computed(()=>userShopZahlungsDaten.value?.kontoinhaber),
            getZahlungsverbindungEmail:computed(()=>userShopZahlungsDaten.value?.email),
            getZahlungsverbindungIBAN:computed(()=>userShopZahlungsDaten.value?.iban),
            getZahlungsverbindungBIC:computed(()=>userShopZahlungsDaten.value?.bic),
            getZahlungsverbindungNameDerBank:computed(()=>userShopZahlungsDaten.value?.nameDerBank),
            getEmailToVerify:computed(()=>userShop.value?.emailToVerify),
            getEmailToVerifyCheck:computed(()=>userShop.value?.emailToVerifyCheck),
            getIBANValid:computed(()=>userShop.value?.ibanValid),
            getBICValid:computed(()=>userShop.value?.bicValid),
            getFehlenAdressDaten:computed(()=>{
                if(typeof(actions.getUserShopAdresse.value)==="undefined"){return true;}
                if(actions.getUserShopAdresse.value?.strasse===null || actions.getUserShopAdresse.value?.plz===null ||
                    actions.getUserShopAdresse.value?.ort===null || actions.getUserShopAdresse.value?.iso3166===null)
                {
                    return true;
                }
                return false;
            }),
            getAnzMissingInfos:computed(()=> actions.getAnzMissingPersonalData.value + actions.getAnzMissingSteuerData.value + actions.getAnzMissingZahlungsData.value),
            getAnzMissingPersonalData:computed(()=>{
                let count : number = 0;
                if(actions.getUserShopEmail.value===null){count++;}
                if(actions.getUserShopTelefon.value===null){count++;}
                if(actions.getFehlenAdressDaten.value){count++;}
                return count;
            }),
            getAnzMissingSteuerData:computed(()=>actions.getSteuerstatus.value===null ? 1 : 0),
            getAnzMissingZahlungsData:computed(()=>{
                if(actions.getZahlungsverbindung.value===null){return 1;}

                let count : number = 0;
                if(actions.getZahlungsverbindungKontoinhaber.value===null){count++;}
                if(actions.getZahlungsverbindung.value==="zv_paypal")
                {
                    if(actions.getZahlungsverbindungEmail.value===null){count++;}
                }
                else
                {
                    if(actions.getZahlungsverbindungBIC.value===null){count++;}
                    if(actions.getZahlungsverbindungIBAN.value===null){count++;}
                    if(actions.getZahlungsverbindungNameDerBank.value===null){count++;}
                }
                return count;
            }),
            isLoaded:computed(()=>loaded),
            SET_USERSHOP_INFO:(shopData:any)=>{
                userShop.value = new UserShop();
                userShop.value.shopName = shopData.ShopName;
                userShop.value.shoplogopath = shopData.ShopLogoURL;
                userShop.value.status = "";
                userShop.value.theme = shopData.Theme;
                userShop.value.themeBgImage = shopData.themeBgImage;
                userShop.value.email = shopData.Email;
                userShop.value.telefon = shopData.Telefon;
                userShop.value.possibleThemes = shopData.possibleThemes;
                userShop.value.isFirmaOrJristischePerson = shopData.isFirmaOrJristischePerson==="1" || false;
                userShop.value.steuerstatus = shopData.Steuerstatus;
                userShop.value.ustid = shopData.UStID;
                userShop.value.zahlungsverbindung = shopData.Zahlungsverbindung;
                userShop.value.emailToVerify = shopData.VerifyEmail;

                userShopAdresse.value = new ShopAdresse();
                userShopAdresse.value.firma = shopData.Verein;
                userShopAdresse.value.ansprechpartner = shopData.Ansprechpartner;
                userShopAdresse.value.strasse = shopData.Strasse;
                userShopAdresse.value.plz = shopData.PLZ;
                userShopAdresse.value.ort = shopData.PLZOrt;
                userShopAdresse.value.iso3166 = shopData.LandKunde;
                userShopAdresse.value.isEU = shopData.EU==="1" || false;

                userShopZahlungsDaten.value = new ShopZahlungsverbindung();
                userShopZahlungsDaten.value.kontoinhaber = shopData.zahlungsdaten["kontoinhaber"];
                userShopZahlungsDaten.value.email = shopData.zahlungsdaten["email"];
                userShopZahlungsDaten.value.iban = shopData.zahlungsdaten["iban"];
                userShopZahlungsDaten.value.nameDerBank = shopData.zahlungsdaten["nameDerBank"];
                userShopZahlungsDaten.value.bic = shopData.zahlungsdaten["bic"];

                //state.isShopOwner = shopData.Login === this.getters.getUsername;
            },
            SET_USERSHOP_STATUS:(status:string)=> (userShop.value as UserShop).status = status,
            SET_USERSHOP_EMAILCHECK:(status:string)=>(userShop.value as UserShop).emailToVerifyCheck = status,
            SET_USERSHOP_NAME : (shopname:string)=> (userShop.value as UserShop).shopName = shopname,
            SET_USERSHOP_LOGOPFAD : (pfad:string)=> (userShop.value as UserShop).shoplogopath = pfad,
            SET_USERSHOP_THEME : (theme:string)=> (userShop.value as UserShop).theme = theme,
            SET_USERSHOP_THEME_BGIMAGE : (image:string)=> (userShop.value as UserShop).themeBgImage = image,
            SET_USERSHOP_EMAIL: (email:string)=> (userShop.value as UserShop).email = email,
            SET_USERSHOP_VERIFYEMAIL : (email:string)=> (userShop.value as UserShop).emailToVerify = email,
            SET_USERSHOP_TELEFON: (telefon:string)=> (userShop.value as UserShop).telefon = telefon,
            SET_USER_SHOPFREIGABE : (freigabe:boolean)=> userFreigegebenFuerShop.value = freigabe,
            SET_USERSHOP_ISFIRMAORPERSON : (value:boolean)=> (userShop.value as UserShop).isFirmaOrJristischePerson = value,
            SET_USERSHOP_STEUERSTATUS : (value:string)=> (userShop.value as UserShop).steuerstatus = value,
            SET_USERSHOP_USTID : (value:string)=> (userShop.value as UserShop).ustid = value,
            SET_USERSHOP_ZAHLUNGSVERBINDUNG: (value:any)=> (userShop.value as UserShop).zahlungsverbindung = value,
            SET_USERSHOP_ZVKONOINHABER : (value:any)=> (userShopZahlungsDaten.value as ShopZahlungsverbindung).kontoinhaber = value,
            SET_USERSHOP_ZVEMAIL : (value:string)=> (userShopZahlungsDaten.value as ShopZahlungsverbindung).email = value,
            SET_USERSHOP_ZVIBAN: (value:string)=> (userShopZahlungsDaten.value as ShopZahlungsverbindung).iban = value,
            SET_USERSHOP_ZVBIC : (value:string)=> (userShopZahlungsDaten.value as ShopZahlungsverbindung).bic = value,
            SET_USERSHOP_ZVNAMEDERBANK: (value:string)=> (userShopZahlungsDaten.value as ShopZahlungsverbindung).nameDerBank = value,
            SET_USERSHOP_IBANCHECKFAILED: ()=>(userShop.value as UserShop).ibanValid = false,
            SET_USERSHOP_IBANCHECKSUCCESS: ()=>(userShop.value as UserShop).ibanValid = true,
            SET_USERSHOP_BICCHECKFAILED: ()=>(userShop.value as UserShop).bicValid = false,
            SET_USERSHOP_BICCHECKSUCCESS: ()=>(userShop.value as UserShop).bicValid = true,
            loadShopInfo:()=>{
                axios({
                    url:"/shop_php/loadShopInfo.php"
                }).then(response=>{
                    actions.SET_USERSHOP_INFO(response.data);
                    loaded.value = true
                })
            },
            changeShopData:()=>{
                let payload = '{"shopName":"'+actions.getUserShopname.value+'",' +
                    '"email":"'+actions.getUserShopEmail.value+'",'+
                    '"isFirmaOrJuristischePerson":"'+actions.getIsFirmaOrPerson.value+'",'+
                    '"steuerstatus":"'+actions.getSteuerstatus.value+'",'+
                    '"ustid":"'+actions.getShopUStID.value+'",'+
                    '"telefon":"'+actions.getUserShopTelefon.value+'"}';
                axios.post("/shop_php/changeshopdata.php", payload
                ).then(response=>{
                    if(response.data === "success") {
                       actions.SET_USERSHOP_STATUS("success");

                    } else if (response.data === "nameExists") {
                        actions.SET_USERSHOP_STATUS("shopNameExists");
                    }
                })
            },
            changeTheme:(data:{ theme: string, bgImage: string })=>{
                let payload = '{"shopName":"'+actions.getUserShopname.value+'",' +
                    '"theme":"'+data.theme+'",'+
                    '"themePicture":"'+data.bgImage+'"}';
                axios.post("/shop_php/changeshoptheme.php", payload
                ).then(response=>{
                    if(response.data === "success") {
                        actions.SET_USERSHOP_STATUS("success");

                    } else if (response.data === "nameExists") {
                        actions.SET_USERSHOP_STATUS("shopNameExists");
                    }
                })
            },
            changeZahlungsdaten:()=>{
                let payload = '{"shopName":"'+actions.getUserShopname.value+'",' +
                    '"zahlungsverbindung":"'+actions.getZahlungsverbindung.value+'",'+
                    '"kontoinhaber":"'+actions.getZahlungsverbindungKontoinhaber.value+'",'+
                    '"email":"'+actions.getZahlungsverbindungEmail.value+'",'+
                    '"iban":"'+actions.getZahlungsverbindungIBAN.value+'",'+
                    '"bic":"'+actions.getZahlungsverbindungBIC.value+'",'+
                    '"nameDerBank":"'+actions.getZahlungsverbindungNameDerBank.value+'"}';
                axios.post("/shop_php/changeZahlungsdaten.php", payload
                ).then(response=>{
                    if(response.data === "success") {
                        actions.SET_USERSHOP_STATUS("success");
                    }
                })
            },
            setUserShopStatus:(status:string)=>actions.SET_USERSHOP_STATUS(status),
            setShopLogoPath:(pfad:string)=>actions.SET_USERSHOP_LOGOPFAD(pfad),

            loadIfNotAlreadyLoaded:()=>{
                if(!loaded.value) {
                    actions.loadShopInfo();
                }
            }
        }

        return actions;

})

