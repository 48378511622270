import {nextTick} from "vue";

export const noop = () => {};

export function afterBrowserRepaintDo(callback:()=>void) {
    setTimeout(() => callback(), 1);
}

function isOfflineHost(host:string) {
    return ["testserver", "localhost", "vsx-webtest"].indexOf(host) > -1 || host.indexOf("gsx") === 0 || host.indexOf("localtunnel.me") > 0;
}

export function getURLPrefixOfflineVsOnline() {
    if(window === undefined) {
        return "/owayo"
    }
    return isOfflineHost(window.location.hostname) ? "/owayo" : "";
}

export function getHTTPForLocalhostHTTPSForOnline() {
    return isOfflineHost(window.location.hostname) ? "http://" : "https://";
}

export function isInViewport(element:HTMLElement):boolean {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function() {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function() {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    }
}

/**
 * Test ob die übergebene EMail-Adresse richtig aussieht.
 * Einnen genaueren Check könnte man nur mit php und der Funktion
 * "checkdnsrr" machen.
 */
export function checkEmail(mailAdresse){
        if(mailAdresse === null || mailAdresse === undefined) {
            return "empty";
        }
        mailAdresse = mailAdresse.trim();

        if(!mailAdresse.includes('@')){return "konf_noAtSign";}
        if((mailAdresse.match(/@/g) || []).length>1){return "konf_toManyATSigns";}

        let mailParts = mailAdresse.split('@'); //localePart = [0], DomainPart = [1];
        let localPart = mailParts[0];
        let domainPart = mailParts[1];
        if(!domainPart.includes('.')){return "konf_noDotInDomain";}
        if(domainPart.includes('..')){return "konf_multipleDots";}
        if(domainPart.startsWith('.')){return "konf_domainStartsWithDot";}

        if((domainPart.match(/[ ,\[\]"():;<>\\'*_!?§$&={}#+°]/g) || []).length>0) {return "konf_domainContainsIllegalChars";}

        for(const part of domainPart.split('.')){
            if(part.startsWith('-')){return "konf_domainPartStartsWithMinus";}
            if(part.endsWith('-')){return "konf_domainPartEndsWithMinus";}
            if(part.length > 63){return "konf_domainPartToLong";}
        }

        if(localPart.startsWith('.')){return "konf_localStartsWithDot";}
        if(localPart.endsWith('.')){return "konf_localEndsWithDot";}
        if(localPart.includes('..') && !localPart.startsWith('"') && !localPart.endsWith('"')){return "konf_localMultipleDots";}

        if(!localPart.startsWith('"') && !localPart.endsWith('"')){
            if((localPart.match(/[ ,\[\]"():;<>\\]/) ||[]).length > 0){return "konf_localContainsIllegalChars";}
        }

        //MMX Abfragen lassen wir erstmal außen vor

        return "valid";
}

export function checkBIC(bic):boolean{
    const bicRegex = /^[A-Za-z]{4}[A-Za-z]{2}[0-9A-Za-z]{2}([0-9A-Za-z]{3})?$/;
    return bicRegex.test(bic);
}

export function checkRoutingNumber(routingnumber):boolean{
    if (!/^\d{9}$/.test(routingnumber)) {
        return false;
    }

    // Perform MOD-10 check
    const digits = routingnumber.split('').map(Number);
    const checksum =
        3 * (digits[0] + digits[3] + digits[6]) +
        7 * (digits[1] + digits[4] + digits[7]) +
        (digits[2] + digits[5] + digits[8]);

    return checksum % 10 === 0;
}

export function checkAccountNumber(accountnumber){
    return /^\d{4,17}$/.test(accountnumber);
}

export function checkIBAN(iban){
    if(iban==='' || iban===undefined){
        return false;
    }
    iban = iban.replace(/[^A-Z0-9]/gi, '').toUpperCase();

    // Check that the total IBAN length is correct as per the country (two letters at start)
    // This regex also checks allowed characters and basic format.
    if (!iban.match(/^[A-Z]{2}\d{2}[A-Z0-9]{0,30}$/)) {
        return false;
    }

    // Move the four initial characters to the end of the string
    iban = iban.slice(4) + iban.slice(0, 4);

    // Replace each letter in the string with two digits
    iban = iban.split('').map(function(char) {
        var code = char.charCodeAt(0);
        return (code >= 'A'.charCodeAt(0) && code <= 'Z'.charCodeAt(0)) ? code - 'A'.charCodeAt(0) + 10 : char;
    }).join('');

    // Perform modulo 97 and check for remainder 1
    var remainder = iban.match(/\d{1,7}/g).reduce((acc, num) => {
        return Number(acc + num) % 97;
    }, '');

    return remainder === 1;
}


export function checkTelefonNr(telefonNr){
    if((telefonNr.match(/^[ 0-9()+-\/]+$/) || []).length>0){
        return "valid";
    }
    else{
        return "notValid";
    }
}

    // In dieser Funktion wird nur auf "string@string.string" geprüft, so dass die simpelsten EingabeFehler abgegriffen werden
export const checkEmailSimple = (mailAdress)=>/\S+@\S+\.\S+/.test(mailAdress);

export function escapeHtml(string) {
    const entityMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': '&quot;',
        "'": '&#39;',
        "/": '&#x2F;'
    };
    return String(string).replace(/[&<>"'/]/g, function (s) {
        return entityMap[s];
    });
}

export const isUUID = id => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id);

export const valuesAreEqualWithinPrecision = (a: number, b: number, precision = 0.01) => Math.abs(a - b) < precision;

export const isEmptyVariable = variable => typeof (variable) === "undefined" || variable === "" || variable === null;


export function createGUID() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

export function looksLikeGUID(s:string):boolean {
    return s.match(/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/) !== null;
}

export function getGetParameterStringsFromKeyValueObject(parameterArray) {
    const paramstrings = [] as string[];
    for (const key in parameterArray) {
        paramstrings.push(key + "=" + parameterArray[key]);
    }
    return "?" + paramstrings.join("&");
}


export function getUrlVars() {
    const regex = /[?&]+([^=&]+)=([^&]*)/gi;
    const vars = {};
    let match = regex.exec(window.location.href);
    while (match !== null) {
        vars[match[1]] = match[2];
        match = regex.exec(window.location.href);
    }
    return vars;
}


export function existsLastLoggedInUserCookie() {
    return document.cookie.split(";").some(function (i) {
        return i.trim().indexOf("LastLoggedInUserCookie=") === 0;
    });
}

export function padLeftToLength(string, length: number, paddingValue: string) {
    while (string.length < length) {
        string = paddingValue + string;
    }
    return string;
}


export function clampValue(value, min, max) {
    return Math.max(Math.min(value, max), min);
}

export const boolToInt = (booleanValue) => booleanValue === true ? 1 : 0;

export function intToBool(intValue) {
    if (typeof (intValue) === "string") {
        intValue = parseInt(intValue, 10);
    }
    return intValue !== 0;
}

export function isMobileBrowser() {
    return /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function stripVersionNumberFromDesignname(designname: string): string {
    return designname.replace(/_[vV]\d+/, "");
}

export function setGlobalVar(key, value) {
    if(typeof(window) !== "undefined") {
        (window as any)[key] = value;
    }
}

export function setBUILD_ID() {
    const parsed = parseInt(import.meta.env.VITE_BUILD_TIMESTAMP, 10);
    const secondIDPart = isNaN(parsed) ? import.meta.env.VITE_BUILD_TIMESTAMP : new Date(parsed).toLocaleString("de-de");
    setGlobalVar("BUILD_ID", [import.meta.env.VITE_APPNAME, secondIDPart].join("; "))
}

export const loadScript = (FILE_URL) => {
    return new Promise<void>((resolve, reject) => {
        try {
            const scriptEle = document.createElement("script");
            scriptEle.type = "text/javascript";
            scriptEle.async = true;
            scriptEle.src =FILE_URL;
            scriptEle.addEventListener("load", () => resolve());
            scriptEle.addEventListener("error", () => reject());
            document.body.appendChild(scriptEle);
        } catch (error) {
            reject();
        }
    });
};

export async function hashStringSHA256(inputString) {
    const stringAsBuffer = new TextEncoder().encode(inputString);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', stringAsBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

export function insertCloudflareDownscalingIntoURL(url:string, width:number|string, height:number|string):string {
    const splitted = url.split("/");
    if(splitted.length < 2) {
        return url;
    }
    const lastPart = splitted.pop();
    splitted.push("cdn-cgi/image/fit=scale-down,width="+width+",height="+height+",format=auto");
    splitted.push(lastPart);
    return splitted.join("/");
}

export function insertCloudflareAutoformatIntoURL(url:string):string {
    const splitted = url.split("/");
    if(splitted.length < 2) {
        return url;
    }
    const lastPart = splitted.pop();
    splitted.push("cdn-cgi/image/format=auto");
    splitted.push(lastPart);
    return splitted.join("/");
}

export function addOnClickOutsideListener(selector:string, callback:()=>void) {
    const outsideClickListener = (event:Event) => {
        const withinBoundaries = event.composedPath().includes(document.querySelector(selector))
        if(!withinBoundaries) {
            callback();
            removeClickListener();
        }
    }
    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
    }
    afterBrowserRepaintDo(()=>document.addEventListener('click', outsideClickListener));
}

export const range=(length:number) => [...Array(length).keys()];