export const getConsentOptions = (translate: (string) => string, onFirstAction:(userPreferences, cookie)=>void, onAccept:()=>void) => {
    return {
        autorun: true,
        current_lang: 'de',

        page_scripts: true,
        languages: {
            'de': {
                consent_modal: {
                    title: translate("cookieCompliance2022title"),
                    description: translate("cookieCompliance2022description")+ "<a class='datenschutzerklaerunglink'>"+translate('km_accept-privacyterms_linktext')+"</a>",
                    primary_btn: {
                        text: translate("cookieCompliance2022AlleAkzeptieren"),
                        role: "accept_all"
                    },
                    secondary_btn: {
                        text: translate("cookieCompliance2022Einstellungen"),
                        role: 'settings'
                    }
                },
                settings_modal: {
                    title: translate("cookieCompliance2022WelcheCookiesTitle"),
                    save_settings_btn: translate("cookieCompliance2022Auswahlspeichern"),
                    accept_all_btn: translate("cookieCompliance2022AlleAkzeptieren"),
                    blocks: [
                        {
                            title: translate("cookieCompliance2022NecessaryTitle"),
                            description: translate("cookieCompliance2022NecessaryDescription"),
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            }
                        },
                        {
                            title: translate('cookieCompliance2022PreferencesTitle'),
                            description: translate("cookieCompliance2022PrefrenecesDescription"),
                            toggle: {
                                value: "preferences",
                                enabled: true,
                                readonly: false
                            }

                        },
                        {
                            title: translate('cookieCompliance2022AnalyticsTitle'),
                            description: translate("cookieCompliance2022AnalyticsDescription"),
                            toggle: {
                                value: "analytics",
                                enabled: true,
                                readonly: false
                            }
                        },
                        {
                            title: translate('cookieCompliance2022MarketingTitle'),
                            description: translate("cookieCompliance2022MarketingDescrription"),
                            toggle: {
                                value: "marketing",
                                enabled: true,
                                readonly: false
                            }
                        }
                    ]

                }
            }
        },
        onFirstAction,
        onAccept
    }
};
