<template>
  <div class="shop__popup--wrapper">
    <div class="open-popup-editshop shop__popup">
      <button class="km__button--close" type="button" name="close" @click="$emit('close_popup')"></button>
      <div class="km__popup--content shop__info">
        <p>{{  $t("shop_pwlock_des") }}</p>
        <ul class="km__list--modules">
          <li class="km__list__element">
            <label class="km__label--text" for="newPw">{{ $t("newPw") }}</label>
            <input class="km__input--standard" type="password" :placeholder="$t('newPw')" id="newPw" v-model="newPW" @change="clearSaveMessage">
            <div v-if="errors.noPwMatch" class="error">{{$t('konf_pwNoMatch')}}</div>
          </li>
          <li class="km__list__element">
            <label class="km__label--text" for="newPwRepeat">{{ $t("newPwRepeat") }}</label>
            <input class="km__input--standard" type="password" :placeholder="$t('newPwRepeat')" id="newPwRepeat" v-model="newPWRepeat" @change="clearSaveMessage">
          </li>
        </ul>
        <button v-if="authendicatedShop" class="km__button--standard blue" @click="removePwLock">{{ $t("shop_removeLock") }}</button>
        <div v-if="saveSuccessful">
          <label class="km__label--saveSuccess">{{ $t('shop_AenderungenGespeichert') }}</label>
        </div>
      </div>
      <div class="km__popup--buttons">
        <button class="km__button--standard" @click="$emit('close_popup')">{{ $t("konf_Close") }}</button>
        <button class="km__button--standard blue" @click="savePwLock">{{ $t("konf_Save") }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {ref} from "vue";
import {sendPostRequest} from "@k/global/axiosFormWrapper";
import {useShopInfos} from "~/store/shopInfosStore";

export default {
  name: "PopupEditPwLock",
  setup(props, context){
    const shopInfos = useShopInfos();
    const shopInfoStore = useShopInfos();
    const newPW = ref("");
    const newPWRepeat = ref("");
    const errors = ref({
      noPwMatch:false
    });
    const saveSuccessful = ref(false);

    return{
      newPW,
      newPWRepeat,
      errors,
      saveSuccessful,
      clearSaveMessage:()=>{
        saveSuccessful.value = false;
      },
      authendicatedShop: computed(() => !shopInfoStore.isAuthenticated),
      savePwLock:()=>{
        if(newPW.value !== newPWRepeat.value) {
          errors.value.noPwMatch = true;
          return;
        }
        sendPostRequest("/shop_php/setPwLock.php", {newPassword: newPW.value, shopname: shopInfos.shopname}).then(reponse=>{
          saveSuccessful.value = true;
          shopInfos.SET_PWLOCKED(true);
        });
      },
      removePwLock:()=>{
        saveSuccessful.value = false;
        newPW.value = "";
        newPWRepeat.value = "";
        sendPostRequest("/shop_php/removePwLock.php", {shopname: shopInfos.shopname}).then(reponse=>{
          saveSuccessful.value = true;
          shopInfos.SET_PWLOCKED(false);
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">

.km__label--saveSuccess {
  font-size: 13px;
  margin-bottom: 4px;
  font-weight: 500;
  color: green;
  display: block;
  padding-top: 30px;
}

</style>