import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "E:/www/shop_nuxt/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "E:/www/shop_nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "E:/www/shop_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "E:/www/shop_nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "E:/www/shop_nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "E:/www/shop_nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "E:/www/shop_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34 from "E:/www/shop_nuxt/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "E:/www/shop_nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "E:/www/shop_nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "E:/www/shop_nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import lazyloading_7dRWX98LowwtjAes7rVQxjorXeDH9ewvW9YTlXOM4mw from "E:/www/shop_nuxt/plugins/lazyloading.js";
import lazyloading_Znp4PFUzeK84Mn3jSQyeMkoVCfugboLMaBeV4zaf7tg from "E:/www/shop_nuxt/plugins/lazyloading.ts";
import owayomenu_47_5xXAhh1su__b7SpWHQhgxxcsboVncnyKvFkSOWwo from "E:/www/shop_nuxt/plugins/owayomenu.js";
import owayomenu_3SwotKSoz7m0PmxfmiE2AXQhls1FFGVuzbEni9uv7qQ from "E:/www/shop_nuxt/plugins/owayomenu.ts";
import store_vB6lJmLi44xwBLSaPjB3ZlURL2nA7UB8l7lG4oIwMnI from "E:/www/shop_nuxt/plugins/store.js";
import store_nyyrexINlreXkw_c648Vi_J6Ipk49t0nNyjm_LBsQj4 from "E:/www/shop_nuxt/plugins/store.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "E:/www/shop_nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_ZFlMR6wvWsXMnrsvqk_kjWBnXLXkwTsdsA30xFvSn34,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  lazyloading_7dRWX98LowwtjAes7rVQxjorXeDH9ewvW9YTlXOM4mw,
  lazyloading_Znp4PFUzeK84Mn3jSQyeMkoVCfugboLMaBeV4zaf7tg,
  owayomenu_47_5xXAhh1su__b7SpWHQhgxxcsboVncnyKvFkSOWwo,
  owayomenu_3SwotKSoz7m0PmxfmiE2AXQhls1FFGVuzbEni9uv7qQ,
  store_vB6lJmLi44xwBLSaPjB3ZlURL2nA7UB8l7lG4oIwMnI,
  store_nyyrexINlreXkw_c648Vi_J6Ipk49t0nNyjm_LBsQj4,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]