
export function multiDimArrayEntryExists(array, keys: any[] = []) {
    try {
        let entry = array;
        keys.forEach(function (key) {
            entry = entry[key];
        });
        return typeof entry !== "undefined";
    } catch (e) {
        return false;
    }
}

export function arrayElementByKeyValue(arrayToProcess, key, value) {
    const index = arrayIndexByKeyValue(arrayToProcess, key, value);
    if (index > -1) {
        return arrayToProcess[index];
    }
    return null;
}

export function arrayIndexByKeyValue(arrayToProcess, key: string, value): number {
    try {
        const length = arrayToProcess.length;
        if (length === 0) {
            return -1;
        }
        for (let i = 0; i < length; i++) {
            // noinspection EqualityComparisonWithCoercionJS
            if (typeof (arrayToProcess[i][key]) !== "undefined" && arrayToProcess[i][key] == value) {
                return i;
            }
        }
        return -1;
    } catch (e) {
        console.error((e as { stack }).stack);
        return -1;
    }
}

export const uniqueValues = (arr) => arr.filter((value, index, self) => self.indexOf(value) === index);
export const getUniqueProperties = (objectArray, propertyGetter) => uniqueValues(objectArray.map(propertyGetter));

export const getRangeArray = anzItems => [...Array(anzItems).keys()]


export function divideIntoArraysOfSize(contentToInsert: Array<any>, subArraySize: number): Array<Array<any>> {
    if (contentToInsert.length === 0) {
        return [];
    }
    const output = [] as any[][];
    let subArray = [] as any[];
    for (let i = 0; i < contentToInsert.length; i++) {
        subArray.push(contentToInsert[i]);
        if (subArray.length === subArraySize) {
            output.push(subArray);
            subArray = [];
        }
    }
    if (subArray.length > 0) {
        output.push(subArray);
    }
    return output;
}


export function arrayGetElementByNoneCaseSensitiveName(arrayToProcess, name) {
    for (const elementName in arrayToProcess) {
        if (name.toLowerCase() === elementName.toLowerCase()) {
            return arrayToProcess[elementName];
        }
    }
    return null;
}


export const repeatNTimes = (n, func: () => void) => {
    getRangeArray(n).forEach(func);
}
