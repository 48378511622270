import {INTERFRAMEMESSAGES} from "./InterframeMessages";
import type {ErrorHandler} from "./ErrorHandler";
import type {Resource, ResourceConstructorArguments} from "./resourcemanager/resource";
import type {IResourceManager} from "./resourcemanager/IResourceManager";
import {uniqueValues} from "./arrayHelper";

declare type IFMessage = {
     messageText:string;
     dataObject:any;
}
function logIfDebug(message) {
    if(window !== undefined && typeof((window as any).DEBUG)!== "undefined" && (window as any).DEBUG === true){
        console.log(message);
    }
}

export class EventHook {
    public eventnames:string[];
    public callback:(data)=>any

    public constructor(eventnames:string|string[], callback:(data)=>void) {
        if(typeof eventnames === "string") {
            this.eventnames = [eventnames];
        } else {
            this.eventnames = eventnames;
        }
        this.callback = callback;
    }
}

interface IEventListener {
    receiveEvent(eventname:string, data?:any)
    receiveEventReturnResult(eventName, data):any
    isSubscribedToEvent(eventname): boolean;
    getSubscribedEvents():string[];
}

interface EventReceivedListener {
    eventWasReceived(eventname, bycomponentName);
}

export class EventListener implements IEventListener{
    eventHooks:EventHook[] = [];
    public constructor() {
        EventVerteiler.subscribe(this);
    }

    getSubscribedEvents(): string[] {
        return uniqueValues(this.eventHooks.reduce((carry, h)=> carry.concat(h.eventnames), []));
    }

    public addEventListener(eventnames:string|string[], f:(data:any)=>void):EventListener {
        this.eventHooks.push(new EventHook(eventnames, f));
        return this;
    }

    public removeAllEventListeners() {
        this.eventHooks = [];
        return this;
    }

    public removeEventListener(eventname) {
        this.eventHooks.forEach(h=>{
            h.eventnames = h.eventnames.filter(n=>n!== eventname);
        })
        this.eventHooks = this.eventHooks.filter(h=>h.eventnames.length>0);
        return this;
    }

    public receiveEvent(eventName, data) {
        this.eventHooks.forEach(h=>{

            if(EventListener.isHookForEventName(h, eventName)) {
                EventVerteiler.eventReceived(eventName, this.constructor.name);
                h.callback(data);
            }
        })
    }

    private static isHookForEventName(hook:EventHook, eventname:string):boolean {
        return hook.eventnames.indexOf(eventname)>-1;
    }

    public receiveEventReturnResult(eventName, data):any {
        for(const hook in this.eventHooks) {
            if(EventListener.isHookForEventName(this.eventHooks[hook], eventName)) {
                return this.eventHooks[hook].callback(data);
            }
        }
        return null;
    }

    isSubscribedToEvent(eventName): boolean {
        return this.eventHooks.some(h=>h.eventnames.indexOf(eventName)>-1);
    }
}


export class EventVerteiler {
    private static eventListenerObjects:IEventListener[] = [];
    public static log = [] as string[];

    public static eventReceivedNotifier = null as EventReceivedListener|null;


    private static errorHandler:ErrorHandler;
    public static resourceManager:IResourceManager;

    private static readonly INTERFRAMEMESSAGES = INTERFRAMEMESSAGES.getMessages();

    public static setResourceManager(resourceManager:IResourceManager) {
        EventVerteiler.resourceManager = resourceManager;
    }

    public static setErrorHandler(errorHandler:ErrorHandler) {
        EventVerteiler.errorHandler = errorHandler;
    }

    public static registerResource(args:ResourceConstructorArguments):Resource {
        return EventVerteiler.resourceManager.registerResource(args)
    }
    public static createTask(taskname:string) {
        return EventVerteiler.resourceManager.createTask(taskname);
    }

    public static handleError(severity, happenedHere, messageToDisplay, callbackAfterMessageClose?) {
        EventVerteiler.errorHandler.handleError(severity, happenedHere, messageToDisplay, callbackAfterMessageClose);
    }

    public static handleErrorTranslated(severity, happenedHere, messageID, messageAddition="", callbackAfterMessageClose?) {
        EventVerteiler.errorHandler.handleError(severity, happenedHere, EventVerteiler.errorHandler.translate(messageID, messageID)+" " + messageAddition, callbackAfterMessageClose);
    }

    public static broadcast(eventName, data?) {
        EventVerteiler.log.push(eventName);
        EventVerteiler.eventListenerObjects.forEach(l=>{
            l.receiveEvent(eventName, data)
        })
    }

    public static requestData(eventName:string, data?):Promise<any> {
        EventVerteiler.log.push("requestData "+eventName);
        return new Promise<any>((resolve, reject)=>{
            for(const l in this.eventListenerObjects) {
                if(this.eventListenerObjects[l].isSubscribedToEvent(eventName)) {
                    this.eventListenerObjects[l].receiveEventReturnResult(eventName, data).then((response:any)=>resolve(response)).catch(()=>reject());
                    return;
                }
            }
            reject();
        })
    }

    // noinspection JSUnusedGlobalSymbols
    public static getEventSubscribers(eventname):string[] {
        return EventVerteiler.eventListenerObjects.reduce((carry, lo) => {
            if (lo.isSubscribedToEvent(eventname)) {
                carry.push(lo.constructor.name);
            }
            return carry;
        }, [] as string[])
    }

    // noinspection JSUnusedGlobalSymbols
    public static getAllSubscribedEvents():string[] {
        return uniqueValues(this.eventListenerObjects.reduce((carry, el)=>carry.concat(el.getSubscribedEvents()), [] as string[]));
    }


    public static subscribe(component:IEventListener) {
        EventVerteiler.eventListenerObjects.push(component);
    }

    public static unsubscribe(component:IEventListener) {
        EventVerteiler.eventListenerObjects = EventVerteiler.eventListenerObjects.filter(c=>c!== component);
    }

    public static receiveMessage(dataObject: IFMessage) {
        logIfDebug("interframeMessage received: "+dataObject.messageText);
        if(EventVerteiler.INTERFRAMEMESSAGES.indexOf(dataObject.messageText) <0) {
            logIfDebug("interframe Message rejected : "+dataObject.messageText);
            return;
        }
        EventVerteiler.broadcast(dataObject.messageText, dataObject.dataObject);
    }

    public static broadcastBlockRequest(blockername, text?) {
        EventVerteiler.broadcast("requestScreenBlockEvent", {requester:blockername, message:text});
    }

    public static broadcastBlockRequestEnded(blockername) {
        EventVerteiler.broadcast("requestScreenBlockEndEvent", {requester:blockername})
    }

    public static eventReceived(eventname, byComponentName) {
        if(EventVerteiler.eventReceivedNotifier !== null) {
            EventVerteiler.eventReceivedNotifier.eventWasReceived(eventname, byComponentName);
        }
    }
}
if (typeof(window)!=="undefined") {
    (window as any).EventVerteiler = EventVerteiler;
}

