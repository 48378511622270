
export class INTERFRAMEMESSAGES {
    public static readonly OPEN_BESTELLUNGEN = "OPEN_BESTELLUNGEN";
    public static readonly OPEN_ADRESSEN = "OPEN_ADRESSEN";
    public static readonly ACTIVATE_KONFIVIEW = "activateKonfiViewEvent";
    public static readonly REMOVE_ENTWURF_FROM_WARENKORB = "removeEntwurfFromWarenkorbEvent";
    public static readonly RESIZE_IFRAME = "resizeIFrame";
    public static readonly WARENKORB_CHANGED = "warenkorbChangedEvent";
    public static readonly USERMANAGEMENT_CLOSE = "closeUserManagement";
    public static readonly USERMANAGEMENT_LOGIN_SUCCESS = "usermanagement_loginSucessful";
    public static readonly USERMANAGEMENT_LOGIN_FAIL = "usermanagement_loginFailed";
    public static readonly ACCOUNTEMAIL_CHANGED = "accountEmailChangedEvent";
    public static readonly ACCOUNTEMAIL_CHANGE_FAILED = "accountEmailChangeFailedEvent";
    public static readonly USERMANAGEMENT_REGISTRATION_SUCCESS = "registrationSucessfulEvent";
    public static readonly DISPLAY_PRIVACYTERMS = "displayPrivacyTermsEvent";
    public static readonly USERMANAGEMENT_PASSWORD_CHANGED = "accountPasswordChangedEvent";
    public static readonly USERMANAGEMENT_PASSWORD_CHANGE_FAILED = "accountPasswordChangeFailedEvent";
    public static readonly USERMANAGEMENT_LOGOUT_REQUESTED = "usermanagementLogoutRequestedEvent";
    public static readonly PREPARE_FOR_LOAD = "prepareForLoadEvent";
    public static readonly ACTIVATE_EXEMPLARDATEN = "activateExemplarDatenEvent";
    public static readonly ADRESSE_DELETED = "adresseDeletedEvent";
    public static readonly IFRAMELOADED = "iframeLoadedEvent";
    public static readonly LOGOUT_SUCCEEDED = "logoutSucessfulEvent";
    public static readonly LOGOUT_FAILED = "logoutFailedEvent";
    public static readonly LOGOUT_REQUESTED = "logoutRequestedEvent";
    public static readonly SWITCH_ADDRESSE = "switchaddress";
    public static readonly NEW_ADRESSE_IN_WARENKORB = "setNewAdresseInWarenkorb";
    public static readonly ACTIVATE_ARTIKELINIFRAME = "activateArtikelInIFrameEvent";
    public static readonly SHOW_PRODUCTAUSWAHL = "showProduktAuswahl";
    public static readonly UPDATE_WARENKORBSYMBOL = "updateWarenkorbsymbolbInHauptmenue";
    public static readonly RELOAD_ADRESSES = "reloadAdresses";
    public static readonly ROUTE_CHANGE = "routechange";
    public static readonly EXEMPLARLISTE_CANCEL = "closeExemplarliste"
    public static readonly EXEMPLARLISTE_SAVED = "ExemplarlisteSaved"
    public static getMessages():string[] {
        const out = [] as string[];
        for(const t in INTERFRAMEMESSAGES) {
            if(typeof (INTERFRAMEMESSAGES[t]) === "string") {
                out.push(INTERFRAMEMESSAGES[t])
            }
        }
        return out;
    }
}

export type IFMessage = {
    messageText;
    dataObject;
}

export class InterframeMessage implements IFMessage{
    public messageText;
    public dataObject;

    public constructor(messageText, dataObject={}) {
        this.messageText = messageText;
        this.dataObject = dataObject;
    }
}
