import {sendPostRequest} from "@k/global/axiosFormWrapper.js";

export class UserManagementLoginBackendBridge {
    private readonly sprache;
    private readonly land;

    public constructor(sprache, land) {
        this.sprache = sprache;
        this.land = land;
    }

    public login(username: string, password: string, success:(username, data)=>void, fail:()=>void) {
        sendPostRequest("/konfigurator_php/sessiondata/#login.php", {
            username: username,
            password: password,
            language: this.sprache,
            land: this.land
        }).then(response=>success(response.data.login, response.data)).catch(fail)

    }
}
