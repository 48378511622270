import {EventVerteiler} from "@k/global/eventVerteiler";
import {INTERFRAMEMESSAGES} from "@k/global/InterframeMessages";
import {KonfiUser} from "@k/global/OwayoUser";
import axios from "axios";
import {Adresse, KonfiguratorAdresseFromBackend} from "@k/global/Adresse";
import {sendPostRequest} from "@k/global/axiosFormWrapper";
import {OwayoWarenkorb} from "@s/js/Warenkorb";
import {UserManagementLoginBackendBridge} from "@km/js/loginBackendBridge";
import {KILogoCredits, LoginResponse} from "@k/js/databaseInterfaceClasses";


export default {
    state: () => ({
        user: null as null|KonfiUser,
        derivedUserDataPath:"",
        adressen:[] as KonfiguratorAdresseFromBackend[],
        adresseToEdit: null as KonfiguratorAdresseFromBackend|null
    }),
    getters: {
        getDerivedUserDataPath:(state)=>state.derivedUserDataPath,
        getUser:(state)=>state.user,
        getAdresseToEdit:(state)=>state.adresseToEdit,
        getEntwuerfeDirectory:state=>["", "logins", state.derivedUserDataPath,  "entwuerfe", ""].join("/"),
        getUsername:state=>{
            if(state.user === null) {
                return "";
            }
            return state.user.userName;
        },

        getWarenkorb:state=>state.user?.warenkorb,
        getEmail:state=>state.user?.email,
        getLoginname:state=>state.user?.userName,
        isLoggedIn:state=>state.user?.loggedIn,
        getAdressen:state=>state.adressen,
        getVisibleAdressen:state=>state.adressen.filter(a=>a.visible),
        getRemainingAILogoCredits:state=>(state.user?.aiLogoCredits - state.user?.aiLogoCreditsUsed) || 0,
    },
    mutations: {
        SET_ADRESSE_TO_EDIT:(state, adresseToEdit) =>{
            state.adresseToEdit = adresseToEdit;
        },
        NEWUSEROBJECT: (state, user) => {
            state.user = user;
        },
        USERNAME_FROM_LOGOUPLOAD_RECEIVED : (state, {username, derivedUserDataPath}) => {
            (state.user as KonfiUser).userName = username;
            (state.user as KonfiUser).derivedUserDataPath = derivedUserDataPath;
        },
        CHANGE_USER_LOGGEDIN_STATUS:(state, isLoggedIn)=>(state.user as KonfiUser).loggedIn = isLoggedIn,
        SET_EMAIL:(state, email)=>(state.user as KonfiUser).email = email,
        SET_ANSPRECHPARTNER:(state, ansprechpartner)=>(state.user as KonfiUser).ansprechpartner = ansprechpartner,
        SET_TELEFON:(state, tel)=>(state.user as KonfiUser).telefon = tel,
        SET_LOGGEDIN:(state, loggedIn)=>(state.user as KonfiUser).loggedIn = loggedIn,
        SET_DERIVED_USER_DATAPATH: (state, derivedUserDataPath)=>state.derivedUserDataPath = derivedUserDataPath,
        SET_ADRESSEN(state, payload) {
            payload.sort((a,b)=>Math.sign(b.id-a.id));
            state.adressen = payload;

        },
        ADRESSE_DELETED(state, id) {
            state.adressen = state.adressen.filter(a=>a.id !== id)
        },
        SET_LOGGEDIN_USER (state, userData) {
            state.user = new KonfiUser(userData.login, new OwayoWarenkorb());
            state.user.loggedIn = true;
            state.user.ansprechpartner = userData.ansprechpartner;
            state.user.email = userData.email;
            state.user.telefon = userData.telefon;
        },
        SET_LOGGEDOUT_USER (state, username) {
            state.user = new KonfiUser(username, new OwayoWarenkorb());
            state.user.loggedIn = false;
        },
        INCREASE_AILOGOGENERATION_USER_CREDITS(state) {
            state.user.aiLogoCreditsUsed++;
        },
        SET_USER_AILOGO_CREDITS(state, data:{credits, used}){
            state.user.aiLogoCredits = data.credits;
            state.user.aiLogoCreditsUsed = data.used;
        }
    },
    actions: {
        login:(context, payload:{username, password, language, land})=>{
            const loginBrige = new UserManagementLoginBackendBridge(payload.language, payload.land);
            loginBrige.login(payload.username, payload.password, (username, data:LoginResponse|string)=>{
                if(typeof(data) === "string") {
                    data = JSON.parse(data) as LoginResponse;
                }
                if(data.status !=="success") {
                    EventVerteiler.broadcast(INTERFRAMEMESSAGES.USERMANAGEMENT_LOGIN_FAIL, {username:data.login, error:data.error})
                } else {
                    EventVerteiler.broadcast(INTERFRAMEMESSAGES.USERMANAGEMENT_LOGIN_SUCCESS, {username, loginResponse: data, status: data.status});
                }
            }, ()=>console.log("login failed"))
        },
        logout:(context)=>{
            axios({
                url:"/konfigurator_php/sessiondata/logout.php"
            }).then(response=>{
                    EventVerteiler.broadcast(
                        INTERFRAMEMESSAGES.LOGOUT_SUCCEEDED,
                        typeof(response.data) === "string" ? JSON.parse(response.data).username : response.data.username
                    );
                    context.commit("SET_LOGGEDIN", false);
            })
        },
        loadKontaktdaten:(context)=>{
            axios({
                url:"/konfigurator_php/sessiondata/getKontaktdaten.php",
                method:"get"
            }).then(response=>{
                if (response.data === false) {
                    return;
                }
                context.commit("SET_EMAIL", response.data.email);
                context.commit("SET_ANSPRECHPARTNER", response.data.ansprechpartner);
                context.commit("SET_TELEFON", response.data.tel)
                context.commit("SET_USER_AILOGO_CREDITS", {credits:response.data.aiLogoCredits, used:response.data.aiLogoCreditsUsed})
            })
        },
        getAnzahlWarenkorbPositionen:(context)=>{

            axios({
                url:"/konfigurator_php/actionmodul/getAnzWarenkorb.php?username="+context.getters.getLoginname
            }).then(response=>{
                context.commit("SET_ANZ_WARENKORBPOSITIONEN", response.data.Anzahl);
            })
        },
        loadAdressen(context, username) {
            axios({url:"/konfigurator_php/actionmodul/loadAdressen.php?username=" + username})
                .then(response=>context.commit("SET_ADRESSEN", response.data.adressen));
        },
        deleteAdress(context, item: Adresse) {
            sendPostRequest("/konfigurator_php/sessiondata/deleteAdresse.php", {adresse:item.id})
                .then(response=>response.data === "success" ? context.commit("ADRESSE_DELETED", item.id) : console.warn("could not delete Adresse"));
        },
        setLoggedInUser(context, data) {
            if (data.status === "success") {
                context.commit("SET_LOGGEDIN_USER", data);
            }
        },
        setLoggedOutUser(context, username) {
            context.commit("SET_LOGGEDOUT_USER", username);
        },
        loadKICredits(context):void {
            axios.get("/konfigurator_php/ki_logos/getKICredits.php").then(response =>{
                context.commit("SET_USER_AILOGO_CREDITS", {credits:(response.data as KILogoCredits).totalCredits, used:(response.data as KILogoCredits).creditsUsed})
            })
        }
    }
}
