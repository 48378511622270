<template>
  <div>
    <div class="team-wrapper" v-if="shopnameSet && (shopIsActive || shopOwner)">
      <ShopHeader
          :shopinfo="shopObj"
          :isOwner="shopOwner"
      />

      <div v-if="authendicated">

      <!--      <ShopFilterMenue :isOwner="shopOwner"/>-->

            <main class="shop__content">
              <NuxtPage
                  :shop="shopObj"
                  :isOwner="shopOwner"
              />
            </main>

            <PopupContainer/>
            <ModuleShopPopupMessages/>
            <ModuleOKOKCancelDialog/>
      </div>
      <div v-else>
        <AccessDenied :show-login="true"/>
      </div>

      <ShopFooter :isOwner="shopOwner"/>
    </div>
    <div class="team-wrapper" v-if="!shopnameSet || (!shopIsActive && !shopOwner)">
      <ShopNotAvailable/>
    </div>
  </div>
</template>

<script lang="ts" setup>


import {EventListener, EventVerteiler} from "@k/global/eventVerteiler";
import "~/assets/scss/style.scss"
import {InterframeCommunicator} from "@k/global/frameCommunicator";
import "owayo_menu/dist/owayo_vue_menu.css"
import {UserManagementConnectorRoutes} from "@k/global/UserManagementConnectorRoutes";
import {useStore} from "vuex";
import {useShopInfos} from "~/store/shopInfosStore";
import {Navigation_opencomponentHandler_SHOP} from "~/navigation_opencomponentHandler_SHOP";
import {initI18n} from "~/init/init_i18n";
import {initEventListeners} from "~/init/initEventListeners";
import {initSession} from "~/init/initSession";
import {loadInitialItemsAndPages, loadInitialShopInfo} from "~/init/loadInitialData";
import {loadWebsitelinks} from "~/init/loadWebsitelinks";
import "vanilla-cookieconsent";
import {CookieBannerhandler} from "@k/global/cookieconsent/cookieConsent";
import {setBUILD_ID} from "@k/global/globalUtils";
import {redirectToCanonicalURL, redirectToThirdLevelDomain} from "~/init/canonicalURL";
import ModuleOKOKCancelDialog from "@km/components/popups/ModuleOKOKCancelDialog.vue";

export type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};


function getLanguageFromShopPfad(shopdirectoryLocalized: string, host:string, path : string) {
  switch (shopdirectoryLocalized) {
    case "shop":
      return "de"
    case "boutique":
      return "fr"
    case "tienda":
      return "es"
    case "store":
    case "store_test":
      if(host.includes("owayo.com")>0 || path.includes("lang=us")>0){
        return "us";
      }
      return "en"
    case "butikk":
      return "no";
    default:
      return "de";
  }
}




function domainOrShopDirectoryLocalizedDoesNotMatchShop(languageFromShopDirectoryLocalized: string) {
  const shopInfos = useShopInfos();
  let doesNotMatch = (shopInfos.getShopLand.toLowerCase() !== shopInfos.getDomainLand.toLowerCase()) || (shopInfos.getShopSprache.toLowerCase() !== languageFromShopDirectoryLocalized.toLowerCase());
  return doesNotMatch;
}



    setBUILD_ID();
    const route = useRoute();
    if (route.params.shopname as undefined | string === undefined) {
      throw createError({
        statusCode: 404,
        message: 'no shopname specified',
        fatal: true
      })
    }
    onUnmounted(() => EventVerteiler.unsubscribe(eventListener));
    onMounted(function () {
      InterframeCommunicator.initWindowListener();
      CookieBannerhandler.init(i18n.t);
      initEventListeners(eventListener, vuexStore, loggedIn, i18n);
      initSession(vuexStore, i18n, loggedIn);
    });
    const requestURL = useRequestURL();
    const language = getLanguageFromShopPfad((route.params.shopdirectoryLocalized as string | undefined) || "shop", requestURL.host, route.fullPath);
    useHead({htmlAttrs: {lang: language}});

    UserManagementConnectorRoutes.initLanguage(language);
    const vuexStore = useStore();
    const i18n = await initI18n(language);

    const loggedIn = ref(false);
    const eventListener = new EventListener();

    await loadInitialShopInfo();
    const shopInfos = useShopInfos();

    if (domainOrShopDirectoryLocalizedDoesNotMatchShop(language)) {
      await redirectToCanonicalURL(shopInfos.getShopSprache, shopInfos.getShopLand, shopInfos.shopname);
    }

    if (import.meta.env.PROD){
      if(!requestURL.host.startsWith('www.')){
        await redirectToThirdLevelDomain(requestURL.host+route.fullPath) ;
      }
    }

    await loadInitialItemsAndPages(shopInfos.shopname, shopInfos.getShopSprache, shopInfos.getShopLand);

    await loadWebsitelinks(language);
    const shopInfoStore = useShopInfos();
    Navigation_opencomponentHandler_SHOP.setShopInfoStore(shopInfoStore);

      const shopObj = computed(() => shopInfoStore.shopInfo);
      const shopOwner = computed(() => shopInfoStore.isShopOwner);
      const shopName = computed(() => shopInfoStore.shopname);
      const shopIsActive = computed(() => shopInfoStore.isShopActive);
      const username = computed(() => vuexStore.getters.getUsername);
      const shoppingcart = computed(() => vuexStore.getters.getWarenkorbPos);
      // const loggedInComp = computed(() => vuexStore.getters.isLoggedIn);
      const shopnameSet = computed(() => route.params.shopname as string | undefined !== undefined);
      const authendicated = computed(() => (shopInfoStore.isAuthenticated || shopInfoStore.isShopOwner));



</script>

<style lang="scss" scoped>
.modal-wrapper {
  background: rgba(255, 255, 255,0.9);
}

</style>
