import axios from "axios";
import Qs from "qs"

export function getFormdata(data) {
    const out = new FormData();
    for(const prop in data) {
        out.append(prop, data[prop]);
    }
    return out;
}

export function sendPostRequest(url, data) {

    return axios({
        url,
        data: Qs.stringify(data),
        method: "post",
        responseType: "json",
    });
}

export function senPostRequestExpectBlob(url, data) {
    const out = new FormData();
    for(const prop in data) {
        out.append(prop, data[prop]);
    }
    return axios({url, data:out, method:"post", responseType:"blob"});
}

export function sendGetRequestExpectBlob(url) {
    return axios({url, method:"get", responseType:"blob"});
}

export function sendGetrequestExpectArraybuffer(url) {
    return axios({url, method:"get", responseType:"arraybuffer"})
}

export function getGetParameterString(values:Object) {
    const toParamString = (key, value)=>encodeURIComponent(key)+"="+encodeURIComponent(value);
    const paramStrings = [] as string[];
    for(const key in values) {
        paramStrings.push(toParamString(key, values[key]))
    }
    return paramStrings.join("&");
}
