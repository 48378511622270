export enum TabNames {
    "NONE",
    "DESIGNS",
    "COLORS",
    "TEXT",
    "LOGO"
}

export class TabData {
    public name: string;
    public id: TabNames;
    public textID: string;

    constructor(id: TabNames, name: string, textID: string) {
        this.id = id;
        this.textID = textID;
        this.name = name;
    }
}

export default [
    new TabData(TabNames.DESIGNS, "DESIGNS", 'konf_Design'),
    new TabData(TabNames.COLORS, "COLORS", 'konf_Farben'),
    new TabData(TabNames.TEXT, "TEXT", 'konf_Text'),
    new TabData(TabNames.LOGO, "LOGO", 'konf_Logo'),
];
