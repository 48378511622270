import {EventListener} from "../eventVerteiler";
import {cookieConsentSettings} from "./cookieJavascriptSettingsTemplate";

export class CookieHandler {

    public static DEVMODE_COOKIENAME = "KONFI_DEVELOPEMENT_MODE";
    public static NOCACHING_COOKIENAME = "OWAYO_NOCACHING";
    public static SHADEREDITOR_COOKIENAME = "SHOW_SHADEREDITOR";


    public static getCookieValue(cookieProperty) {
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            const c = ca[i].trim();
            if (c.indexOf(cookieProperty + "=") === 0) {
                return c.substring(cookieProperty.length + 1, c.length);
            }
        }
        return '';
    }

    public static deleteGoogleCookies() {
        const cookies = document.cookie.split(";");
        cookies.forEach(c=>{

            const [name, val] = c.split('=').map(c => c.trim());
            if(/^_g/.test(name)) {
                CookieHandler.deleteCookie(name);
            }
        })
    }

    public static deleteCookie = name=>CookieHandler.createCookie(name, "", -1);
    public static createCookie=(name, value, days)=>{
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString ();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    public static konfiDevelopementCookieSet():boolean {
        return CookieHandler.getCookieValue(CookieHandler.DEVMODE_COOKIENAME) === "1";
    }
}

export class CookieConsentInitializer extends EventListener{
    public constructor() {
        super();
        this.addEventListener("languageIsKnownEvent", ()=> CookieConsentInitializer.initNewCookieConsentPopup())
    }


    public static initNewCookieConsentPopup() {
        $.getScript("https://static.owayo-cdn.com/newhp/js/resource/popper.min.js", function () {
            $.getScript("https://static.owayo-cdn.com/newhp/js/resource/bootstrap.min.js", function () {
                ($('body') as unknown as {bsgdprcookies}).bsgdprcookies(cookieConsentSettings);
                $('#cookiesBtn').on('click', function () {
                    ($('body') as unknown as {bsgdprcookies}).bsgdprcookies(cookieConsentSettings, 'reinit');
                });
            });
        });
    }
}


export enum CookieConsentCookieNames {
    'PREFERENCES'= 'CookiePreferences',
    'MARKETING'= 'CookieShowmarketing',
    'SHOWPREFERENCES'= 'CookieShowpreferences',
    'ANALYTICS'= 'CookieShowanalytics',
    VERSION="CookieConsentVersion"
}

export class CookieConsentHandler {
    public static analyticCookiesAllowed = ()=>CookieHandler.getCookieValue(CookieConsentCookieNames.ANALYTICS);
    public static marketingCookiesAllowed = ()=>CookieHandler.getCookieValue(CookieConsentCookieNames.MARKETING);
    public static newCookieConsentShouldBeUsed = ()=>CookieHandler.getCookieValue(CookieConsentCookieNames.VERSION) === "2.0";

    public static cookieConsentAlreadyGiven = ()=>CookieHandler.getCookieValue(CookieConsentCookieNames.PREFERENCES) !== "";
}
