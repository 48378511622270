type CurrencyDisplay = "symbol" | "code" | "name";
export interface NumberFormats {
    [index: string]: { currency: { style: "currency", currency: string, currencyDisplay?: CurrencyDisplay|undefined } }
}

export default {
    'en-us': {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    'us': {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    'jp': {
        currency: {
            style: 'currency',
            currency: 'JPY',
            currencyDisplay: 'symbol'
        }
    },
    'de': {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        }
    },
    'de-ch': {
        currency: {
            style: 'currency',
            currency: 'CHF',
            currencyDisplay: 'symbol'
        }
    },
    'ch': {
        currency: {
            style: 'currency',
            currency: 'CHF',
            currencyDisplay: 'symbol'
        }
    },
    'en': {
        currency: {
            style: 'currency',
            currency: 'GBP',
            currencyDisplay: 'symbol'
        }
    },
    'fr': {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        }
    }
} as NumberFormats;
