import axios from "axios";
export default {
    state: () => ({
        warenkorbPositionen:[],
        anzWarenkorbPositionen:0
    }),
    getters: {
        getWarenkorbPos(state) {
            return state.warenkorbPositionen;
        },
        getAnzWarenkorbPositionen: (state) => state.warenkorbPositionen.length
    },
    mutations: {
        SET_WARENKORBPOSITIONEN:(state, positionen)=>{
            state.warenkorbPositionen = positionen.map(ele => ({
                imageURL: ele.imageURL,
                anz: ele.anz,
                price: parseFloat(ele.price) * parseInt(ele.anz),
                productShort: ele.productShort,
                waehrung: ele.waehrung,
                mengenbezeichner: ele.mengenbezeichner,
                amountString: ele.amountString,
                groesse: ele.groesse
            }))
        },
        SET_ANZ_WARENKORBPOSITIONEN:(state, anz)=>state.anzWarenkorbPositionen = anz
    },
    actions: {
        loadWarenkorbUebersicht(context, data) {
            const lang = data?.land || "de";
            const land = data?.lang || "de";
            const shop = data?.shop || "";
            let url = "/konfigurator_php/global/wkmenuContentLoader.php"+"?lang="+lang+"&land="+land;
            if (shop!==""){
                url = url+"&shop="+shop;
            }
            axios({
                url:url,
            }).then(response=>context.commit("SET_WARENKORBPOSITIONEN", response.data))
        }

    },
}
