
import axios from "axios";
import {getConsentOptions} from "./cookieconsentOptions";
import {getURLPrefixOfflineVsOnline} from "../globalUtils";
import {CookieHandler} from "./cookieHandler";
import {EventVerteiler} from "../eventVerteiler";

export enum CookieConsentCategories {
    "necessary" = "necessary",
    "preferences" = "preferences",
    "analytics" = "analytics",
    "marketing" = "marketing"
}

export class CookieBannerhandler {
    public static init(translate:(string)=>string):void {
        const consentOptions = getConsentOptions(translate, CookieBannerhandler.onFirstAction, ()=>EventVerteiler.broadcast("CookieConsentChoiceMadeEvent"));

        // @ts-ignore
        const cc = initCookieConsent();
        cc.run(consentOptions)
    }

    private static onFirstAction(user_preferences) {
        CookieBannerhandler.writeCookiesLike_bsgfprcookiesWouldDoIt(user_preferences.accepted_categories, user_preferences.rejected_categories)
        axios.post(getURLPrefixOfflineVsOnline() + "/newhp/php/sessionSetCookies.php?cockieconsent=true").then(function (data) {
            if (typeof (EventVerteiler) !== "undefined") {
                EventVerteiler.broadcast("CookieConsentChoiceMadeEvent");
            }
        })
    }

    private static write_bsgdprcookies(acceptedCategories: CookieConsentCategories[]) {
        acceptedCategories.forEach(c => CookieHandler.createCookie("CookieShow" + c, "allow", 365))
    }
    private static delete_bsgdprcookies(rejectedCategories: CookieConsentCategories[]) {
        rejectedCategories.forEach(c => CookieHandler.deleteCookie("CookieShow" + c))
    }

    private static writeCookiesLike_bsgfprcookiesWouldDoIt(acceptedCategories: CookieConsentCategories[], rejectedCategories: CookieConsentCategories[]) {
        CookieBannerhandler.write_bsgdprcookies(acceptedCategories);
        CookieBannerhandler.delete_bsgdprcookies(rejectedCategories);
        CookieHandler.createCookie("CookiePreferences", JSON.stringify(acceptedCategories), 365);
    }
}
