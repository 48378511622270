import {IUserWrapper, UserWrapperBase} from "@k/js/userWrapper";
import {OwayoWarenkorb} from "@km/../../shoppingcart/js/Warenkorb";
import {KonfiUser} from "@k/global/OwayoUser";
import {EntwurfListenEintragFromBackend} from "@k/js/databaseInterfaceClasses";


export class UserStoreWrapper extends UserWrapperBase implements IUserWrapper {
    private store;

    public constructor(store) {
        super();
        this.store = store;
    }

    getRemainingAILogoCredits() {
        return this.store.getters.getRemainingAILogoCredits
    }

    createNewUser(username: string, warenkorb?: OwayoWarenkorb | null) {
        this.store.commit("NEWUSEROBJECT", new KonfiUser(username, warenkorb === null ? this.store.getters.getWarenkorb: warenkorb))
    }
    getDerivedUserDataPath = () => this.store.getters.getDerivedUserDataPath;
    getEmail = (): string => this.store.getters.getEmail;
    getEntwuerfe = () => this.store.getters["EntwurfListeStore/getEntwurfListe"]
    getUserName = () => this.store.getters.getLoginname;
    isLoggedIn = (): boolean => this.store.getters.isLoggedIn;
    loadAdressen = () => this.store.dispatch("loadAdressen", this.store.getters.getLoginname)
    loadKontaktdaten = () => this.store.dispatch("loadKontaktdaten");
    loadAnzWarenkorbPositionen = () =>this.store.dispatch("getAnzahlWarenkorbPositionen");
    setAdressen = adressen => this.store.commit("SET_ADRESSEN", adressen);
    setAnsprechPartner = (ansprechpartner: string) =>this.store.commit("SET_ANSPRECHPARTNER", ansprechpartner)
    setDerivedUserDataPath = (derivedUserdataPath: string) =>this.store.commit("SET_DERIVED_USER_DATAPATH", derivedUserdataPath);
    setEmail = (email: string) => this.store.commit("SET_EMAIL", email);
    setLoggedIn = (isLoggedIn: boolean) =>this.store.commit("SET_LOGGEDIN", isLoggedIn);
    setTelefon = (tel: string) => this.store.commit("SET_TELEFON", tel);
    setEntwuerfe = (entwuerfe:EntwurfListenEintragFromBackend[])=>this.store.commit("EntwurfListeStore/SET_ENTWURF_LISTE", entwuerfe);

    addEntwurf(entwurf: EntwurfListenEintragFromBackend) {
        this.store.commit("EntwurfListeStore/ADD_NEW_ENTWURFLISTENENTRY", entwurf);
    }

    incrementAiLogoCreditsUsed() {
        this.store.commit("INCREASE_AILOGOGENERATION_USER_CREDITS");
    }
}
